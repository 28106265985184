import { extendTheme } from '@chakra-ui/react'
// import { createBreakpoints } from '@chakra-ui/theme-tools'

export default extendTheme({
  colors: {},
  shadows: {
    menu: '0px 6px 17px 0px rgba(109,132,178,0.2)'
  },
  config: {
    cssVarPrefix: 'chakra',
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
})
