import React from 'react'
import PersonalDetailItem from '../../../../../../components/PersonalDetailItem'
import { BankAccountDto, UserDto } from '../../../../../../types/coreApi-types'
import { Spinner } from '@chakra-ui/react'
import { useSuspendAccountMutation } from '../../../../../../redux/services/coreApi'

interface UserDetailsProps {
  client?: UserDto
  isLoading: boolean
}

const UserDetails: React.FC<UserDetailsProps> = ({ client, isLoading }) => {
  const isOrganisation = client?.role === 'Organization'
  const isMember = client?.role === 'Member'
  const isIndividual = client?.role === 'Individual'
  const isUBO = client?.isUltimateBeneficialOwner

  const [suspendAccount] = useSuspendAccountMutation()

  if (isLoading) {
    return (
      <div className="mt-10 flex justify-center flex-col items-center">
        <Spinner size={'lg'} />
        <p className="text-description mt-4">Please wait ...</p>
      </div>
    )
  }

  return (
    <div className="space-y-3">
      <h1 className="h1">User details</h1>
      {/* <div className="grid grid-cols-3 gap-2">
        {fund.map(({ title, value }, i) => {
          return (
            <div key={i} className="card gap-2">
              <p className="text-description">{title}</p>
              <p className="text-xl font-semi mb-0">{value}</p>
            </div>
          )
        })}
      </div> */}
      <div className="card gap-5">
        <PersonalDetailItem title="Account type" value={client?.role || ''} />
        <PersonalDetailItem title="Full name" value={client?.name || ''} />

        {isOrganisation && (
          <PersonalDetailItem title="VAT" value={`${client?.vatNumber}`} />
        )}

        <PersonalDetailItem title="Email" value={client?.email || ''} />
        <PersonalDetailItem
          title={isOrganisation ? 'KYB' : 'KYC'}
          value={client?.kycDetails.kycStatus || ''}
        />
        {isOrganisation && (
          <PersonalDetailItem
            title="Questionaire status"
            value={`${client?.kycDetails.questionnaireStatus}`}
          />
        )}
        <PersonalDetailItem title="Address" value={client?.address || ''} />
        <PersonalDetailItem title="Phone" value={client?.phone || ''} />
        <PersonalDetailItem
          title="Country"
          value={`${client?.country.name} (${client?.country.isoCode})`}
        />

        {isMember && (
          <PersonalDetailItem
            title="Is UBO"
            value={`${isUBO ? 'Yes' : 'No'}`}
          />
        )}
        {isUBO && (
          <div className="space-y-5">
            <PersonalDetailItem
              title="Company title"
              value={client?.companyTitle}
            />
            <PersonalDetailItem
              title="Share of company"
              value={
                client.sharePercentageOfCompany
                  ? `${client?.sharePercentageOfCompany}%`
                  : ''
              }
            />
          </div>
        )}
        {isIndividual && (
          <>
            <PersonalDetailItem
              title="Intended products to use"
              value={`${client?.intendedProductsToUse?.map(i => ` ${i}`)}`}
            />
            <PersonalDetailItem
              title="Account purpose"
              value={`${client?.accountPurpose}`}
            />
            <PersonalDetailItem
              title="Source of funds"
              value={`${client?.sourceOfFunds}`}
            />
          </>
        )}

        <div className="my-5">
          {client?.isSuspended ? (
            <button
              className="approve-button"
              onClick={async () => {
                await suspendAccount({
                  userId: client.id,
                  isSuspended: false,
                })
              }}
            >
              Un-suspend account
            </button>
          ) : (
            <button
              className="button-danger"
              onClick={async () => {
                await suspendAccount({
                  userId: client!.id,
                  isSuspended: true,
                })
              }}
            >
              Suspend account
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserDetails
