import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import StartKycScreen from './StartKycScreen'
import KycQuestionnaireScreen from './KycQuestionnaireScreen'
import KycDocumentsUploadScreen from './KycDocumentsUploadScreen'
import KycSuccessScreen from './KycSuccessScreen'
import KybUboScreen from './KybUboScreen'

interface KycNavigatorProps {}

const KycNavigator: React.FC<KycNavigatorProps> = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/start`}>
        <StartKycScreen />
      </Route>
      <Route path={`${path}/owners`}>
        <KybUboScreen />
      </Route>
      {/* <Route path={`${path}/questionnaire`}>
        <KycQuestionnaireScreen />
      </Route>
      <Route path={`${path}/documents`}>
        <KycDocumentsUploadScreen />
      </Route>
      <Route path={`${path}/success`}>
        <KycSuccessScreen />
      </Route> */}
    </Switch>
  )
}

export default KycNavigator
