import { CurrencyDto } from '../../../../../../types/coreApi-types'
import { formatNumber } from '../../../../../../utils/formatters'

interface PerformanceDataProps {
  title: string
  currency: CurrencyDto
  amount: number
}

const PerformanceData: React.FC<PerformanceDataProps> = ({
  title,
  currency,
  amount,
}) => {
  return (
    <div>
      <h1 className="text-3xl font-regular">
        {formatNumber(amount, currency)}
      </h1>
      <p className="text-sm font-light text-brand-dark-text-subtle-description">
        {title}
      </p>
    </div>
  )
}

export default PerformanceData
