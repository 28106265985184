export const getInitials = (name: string) => {
  return name
    .trim()
    .split(' ')
    .reduce(
      (acc, cur, idx, arr) =>
        acc +
        (arr.length > 1
          ? idx == 0 || idx == arr.length - 1
            ? cur.substring(0, 1)
            : ''
          : cur.substring(0, 2)),
      ''
    )
    .toUpperCase()
}