import React from 'react'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import AnimateHeight from './AnimateHeight'

interface FormSelectProps {
  label?: string
  placeholder?: string
  error?: FieldError
  registeredForm?: UseFormRegisterReturn
  disabled?: boolean
  id?: string
  options: string[]
  value?: string
}

const FormSelect: React.FC<FormSelectProps> = ({
  label,
  placeholder,
  error,
  registeredForm,
  disabled,
  id,
  options,
  value
}) => {
  return (
    <div className="space-y-0.5">
      {label && (
        <label htmlFor={label} className="text-description">
          {label}
        </label>
      )}

      <div className="">
        <select
          className={'input cursor-pointer'}
          placeholder={placeholder}
          id={id ?? label}
          aria-disabled={disabled}
          disabled={disabled}
          defaultValue={placeholder}
          {...registeredForm}
        >

          {placeholder && <option value="">{placeholder}</option>}

          {options.map((option, idx) => {
            return (
              <option value={option} key={idx}>
                {option}
              </option>
            )
          })}
        </select>
      </div>

      <AnimateHeight isVisible={error !== undefined}>
        <div className="flex items-center gap-1 text-warning">
          <span>{error?.message}</span>
        </div>
      </AnimateHeight>
    </div>
  )
}

export default FormSelect
