import React, { useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { RiMenu2Line } from 'react-icons/ri'
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
} from '@chakra-ui/react'
import { MdOutlineClose } from 'react-icons/md'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faRightLeft,
  faUser,
  faWallet,
  faChartSimple,
  faBank,
  faIdCard,
  faRightFromBracket,
  faComment,
  faEnvelope,
  faPhone,
  faBoltLightning,
  faBolt,
  faUserGroup,
  faList,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons'
import { RootState } from '../../../redux/store'
import { useSelector } from 'react-redux'
import MenuItem from '../../../components/MenuItem'
import penningSymbol from '../../../assets/symbol.png'
import { VERSION } from '../../../constants'
import WelcomeModal from '../../../components/WelcomeModal'
import { useAuth0 } from '@auth0/auth0-react'
import { AUTH0_LOGOUT_URL } from '../../../constants'
import DefaultModal from '../../../components/DefaultModal'
import NeedHelp from '../../../components/NeedHelp'
import ZapIcon from '../../../assets/icons/zap-stroke-rounded'
import AnalyticsUpIcon from '../../../assets/icons/analytics-up-stroke-rounded'
import ArrowDataTransferHorizontalIcon from '../../../assets/icons/arrow-data-transfer-horizontal-stroke-rounded'
import Wallet03Icon from '../../../assets/icons/wallet-03-stroke-rounded'
import UserIcon from '../../../assets/icons/user-stroke-rounded'
import BankIcon from '../../../assets/icons/bank-stroke-rounded'
import UserGroupIcon from '../../../assets/icons/user-group-stroke-rounded'
import Money01Icon from '../../../assets/icons/money-01-stroke-rounded'
import DashboardSpeed02Icon from '../../../assets/icons/dashboard-speed-02-stroke-rounded'

interface Props {}

const AppMenu: React.FC<Props> = () => {
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const betaModal = useDisclosure()

  const contactModal = useDisclosure()

  const { logout } = useAuth0()

  const sessionRole = useSelector(
    (state: RootState) => state.session?.user?.role
  )

  type Page = {
    title: string
    path: string
    icon: JSX.Element
    comingSoon?: boolean
    isNewFeature?: boolean
    links?: { title: string; path: string }[]
  }

  const IndividualPages: Page[] = [
    {
      title: 'Get started',
      path: '/app/get-started',
      icon: <ZapIcon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'Investor',
      path: '/app/investor',
      icon: <AnalyticsUpIcon className="w-5 h-5 text-inherit" />,
      comingSoon: true,
      // links: [
      //   {
      //     title: "Overview",
      //     path: "/app/investor/overview"
      //   },
      //   {
      //     title: "Deposit",
      //     path: "/app/investor/deposit"
      //   },
      //   {
      //     title: "Withdraw",
      //     path: "/app/investor/withdraw"
      //   },
      // ]
    },
    {
      title: 'Broker service',
      path: '/app/broker-service',
      icon: (
        <ArrowDataTransferHorizontalIcon className="w-5 h-5 text-inherit" />
      ),
    },
    {
      title: 'Wallets',
      path: '/app/wallets',
      icon: <Wallet03Icon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'Bank Accounts',
      path: '/app/bank-accounts',
      icon: <BankIcon className="w-5 h-5 text-inherit" />,
    },

    {
      title: 'Account',
      path: '/app/account',
      icon: <UserIcon className="w-5 h-5 text-inherit" />,
    },
  ]

  const adminPages: Page[] = [
    {
      title: 'Clients',
      path: '/app/admin/clients',
      icon: <UserGroupIcon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'Transfers',
      path: '/app/admin/transfers',
      icon: (
        <ArrowDataTransferHorizontalIcon className="w-5 h-5 text-inherit" />
      ),
    },
    {
      title: 'Currency Overview',
      path: '/app/admin/currencies',
      icon: <Money01Icon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'OTC Performance',
      path: '/app/admin/performance',
      icon: <DashboardSpeed02Icon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'Account',
      path: '/app/account',
      icon: <UserIcon className="w-5 h-5 text-inherit" />,
    },
  ]

  const utilsItems = [
    {
      title: 'Log out',
      icon: <FontAwesomeIcon icon={faRightFromBracket} size="sm" />,
      onClick: () => logout({ returnTo: AUTH0_LOGOUT_URL }),
    },
  ]

  const pagesToMap = sessionRole === 'Admin' ? adminPages : IndividualPages

  window.addEventListener('storage', () => {
    forceUpdate()
  })

  const isDynamicSidebarActivated = () => {
    return localStorage.getItem('dynamic-sidebar') === 'on'
  }

  return (
    <div
      className={`${
        isDynamicSidebarActivated()
          ? 'md:w-[55px] w-full '
          : 'w-full md:w-[250px]'
      } h-[60px] md:h-full w-full transition-all shrink-0 duration-300 group bg-brand-app-menu dark:bg-brand-dark-card flex md:flex-col p-2  border-r-[1px] border-solid border-transparent dark:border-r-brand-dark-card-border z-50`}
    >
      <WelcomeModal isOpen={betaModal.isOpen} onClose={betaModal.onClose} />

      <div className="hidden md:flex md:flex-col gap-1 justify-between h-full">
        <div>
          <div className="flex items-center gap-3 mb-10 p-1.5 overflow-hidden whitespace-nowrap">
            <div className="h-8 w-8 flex items-center justify-center shrink-0 dark:bg-brand-dark-background bg-brand-app-menu-hover rounded-lg">
              <img src={penningSymbol} alt="Penning logo" className="h-5" />
            </div>
            {isDynamicSidebarActivated() ? (
              <></>
            ) : (
              <div
                onClick={betaModal.onOpen}
                className="bg-brand-dark-orange py-1 px-2 rounded-full cursor-pointer"
              >
                <h1 className="text-white font-semi text-[10px]">
                  BETA (v{VERSION})
                </h1>
              </div>
            )}
          </div>

          {pagesToMap.map((item, i) => {
            const isActive = history.location.pathname.includes(item.path)
            return (
              <MenuItem
                key={i}
                icon={item.icon}
                title={item.title}
                onClick={() => {
                  if (item.links) {
                    history.push(item.links[0].path)
                  } else {
                    history.push(item.path)
                  }
                }}
                isNewFeature={item.isNewFeature}
                isActive={isActive}
                comingSoon={item.comingSoon}
                links={item.links}
              />
            )
          })}
        </div>
        <div>
          <NeedHelp />
          {utilsItems.map((item, i) => {
            return (
              <MenuItem
                key={i}
                icon={item.icon}
                title={item.title}
                onClick={item.onClick}
                isActive={false}
              />
            )
          })}
        </div>
        <DefaultModal
          isOpen={contactModal.isOpen}
          isLoading={false}
          onClose={contactModal.onClose}
          title="Need help?"
        >
          <div className="p-4 text-sm flex flex-col gap-2">
            <div className="bg-white px-4 py-3 rounded-md font-medium flex items-center gap-2 w-full">
              <FontAwesomeIcon icon={faEnvelope} />
              m@penning.dk
            </div>
            <div className="bg-white px-4 py-3 rounded-md font-medium flex items-center gap-2 w-full">
              <FontAwesomeIcon icon={faPhone} />
              +45 30 22 00 33
            </div>
          </div>
        </DefaultModal>
      </div>
      <div className="md:hidden text-white flex items-center px-1 ">
        <div className="p-1" onClick={onOpen}>
          <RiMenu2Line color="#fff" size={24} />
        </div>
        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
          <DrawerOverlay />
          <DrawerContent bg="#29383B">
            <div className="flex items-center justify-between px-5 pt-5">
              {/* <img className="w-[50px]" src={logo} alt="Penning logo" /> */}

              <div className="p-4 rounded-full" onClick={onClose}>
                <MdOutlineClose size={26} color="#fff" />
              </div>
            </div>

            <DrawerBody mt="20px">
              <div className="flex flex-col gap-2">
                {pagesToMap.map((item, i) => {
                  const isActive = history.location.pathname.includes(item.path)

                  return (
                    <MenuItem
                      key={i}
                      icon={item.icon}
                      isActive={isActive}
                      onClick={() => {
                        history.push(item.path)
                        onClose()
                      }}
                      title={item.title}
                      comingSoon={item.comingSoon}
                    />
                  )
                })}
                <div className="mt-10">
                  <NeedHelp />
                  {utilsItems.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        icon={item.icon}
                        title={item.title}
                        onClick={item.onClick}
                        isActive={false}
                      />
                    )
                  })}
                </div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </div>
    </div>
  )
}

export default AppMenu
