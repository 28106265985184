import { Spinner } from '@chakra-ui/react'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import PopOver from '../../../../../components/PopOver'
import { CurrencyDto } from '../../../../../types/coreApi-types'
import { formatNumber } from '../../../../../utils/formatters'

interface FeeContainerProps {
  payCurrency?: CurrencyDto
  exchangeRate?: number
  receiveCurrency?: CurrencyDto
  baseFeeRate: number
  bankFeeRate: number
  payAmount: number
  quotationIsFetching: boolean
}

const FeeContainer: React.FC<FeeContainerProps> = ({
  payCurrency,
  exchangeRate,
  receiveCurrency,
  baseFeeRate,
  bankFeeRate,
  payAmount,
  quotationIsFetching,
}) => {
  return (
    <div className="space-y-1 mt-3">
      <div className="text-description flex justify-between">
        <span>Estimated rate</span>
        <span className="text-brand-accent dark:text-white">
          {exchangeRate &&
            receiveCurrency &&
            payCurrency &&
            `${formatNumber(1, payCurrency)} ≈ ${formatNumber(
              exchangeRate,
              receiveCurrency
            )}`}
        </span>
      </div>
      <div className="text-description flex justify-between">
        <span>Processing fee, {(baseFeeRate * 100).toFixed(2)}%</span>
        {quotationIsFetching ? (
          <Spinner size="xs" />
        ) : (
          <span className="text-brand-accent dark:text-white">
            {isNaN(baseFeeRate)
              ? 2
              : payCurrency &&
                formatNumber(baseFeeRate * payAmount, payCurrency)}
          </span>
        )}
      </div>
      {bankFeeRate > 0 && (
        <div className="text-description flex justify-between">
          <span>Bank fee, {(bankFeeRate * 100).toFixed(2)}%</span>
          {quotationIsFetching ? (
            <Spinner size="xs" />
          ) : (
            <span className="text-brand-accent dark:text-white">
              {isNaN(bankFeeRate)
                ? 0
                : payCurrency &&
                  formatNumber(bankFeeRate * payAmount, payCurrency)}
            </span>
          )}
        </div>
      )}
      <PopOver
        renderTrigger={
          <div className="text-description inline-block hover:underline cursor-pointer">
            How is it calculated?
          </div>
        }
      >
        <div className="text-xs">
          <div className="flex items-center gap-2 text-[13px] mb-4 text-brand-light-text-default">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="text-brand-accent dark:text-white text-sm"
            />
            <p className="font-semi dark:text-white">How is it calculated?</p>
          </div>
          <div className="text-description">
            <p className="mb-1 font-semi dark:text-white">Exhange rate</p>
            The exhange rate is an estimate we update every 15 minutes, the
            exact exchange rate will be shown when you have generated your swap.
            <p className="mb-1 mt-2 font-semi dark:text-white">
              Processing fee
            </p>
            We take a processing fee for each transaction, which is based on
            your associated fee structure and the amount and currency being
            transacted.
          </div>
        </div>
      </PopOver>
    </div>
  )
}

export default FeeContainer
