import React from 'react'
import DefaultModal from './DefaultModal'

interface SuspendedAccountModal {
  isOpen: boolean
  onClose: () => void
}

const SuspendedAccountModal: React.FC<SuspendedAccountModal> = ({
  isOpen,
  onClose,
}) => {
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={false}
      title="Your account has been suspended"
    >
      <div className="flex flex-col items-center">
        <div className="text-description m-8 space-y-10">
          <p>
            Your account has been temporarily suspended for trading, please
            contact support at j@penning.dk for further information.
          </p>
          <button className="button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default SuspendedAccountModal
