import React, { useState } from 'react'
import PageWrapper from '../../../../../components/Pagewrapper'
import { useHistory, useParams } from 'react-router-dom'
import {
  useGetAllBankAccountsByUserIdQuery,
  useGetAllMembersByOrganizationIdQuery,
  useGetAllSwapsByIdQuery,
  useGetAllTransferActivityQuery,
  useGetAllWalletsByUserIdQuery,
  useGetUserQuery,
} from '../../../../../redux/services/coreApi'
import { PageTab } from '../../../../../types/internal-types'
import UserDetails from './components/UserDetails'
import KYC from './components/KYC'
import Members from './components/Members'
import Activity from './components/Activity'
import BankAccounts from './components/BankAccounts'
import Wallets from './components/Wallets'
import Transfers from './components/Transfers'

interface ClientScreenProps {}

const ClientScreen: React.FC<ClientScreenProps> = () => {
  const history = useHistory()

  const { id } = useParams<{ id: string }>()

  const { data: client, isLoading: isLoadingUser } = useGetUserQuery(id)
  const { data: members, isLoading: isLoadingMembers } =
    useGetAllMembersByOrganizationIdQuery(
      { organizationId: id },
      { skip: !client || client.role !== 'Organization' }
    )

  const { data: bankAccounts, isLoading: isLoadingBankAccounts } =
    useGetAllBankAccountsByUserIdQuery({ userId: id })
  const { data: wallets, isLoading: isLoadingWallets } =
    useGetAllWalletsByUserIdQuery({ userId: id })
  const { data: swaps, isLoading: isLoadingSwaps } = useGetAllSwapsByIdQuery({
    userId: id,
  })
  const { data: activity, isLoading: isLoadingActivity } =
    useGetAllTransferActivityQuery({ userId: id })

  const isLoading =
    isLoadingUser ||
    isLoadingBankAccounts ||
    isLoadingWallets ||
    isLoadingMembers ||
    isLoadingActivity ||
    isLoadingSwaps

  const [tab, setTab] = useState<
    | 'user-details'
    | 'kyb/kyc'
    | 'members'
    | 'activity'
    | 'transfers'
    | 'bank-accounts'
    | 'wallets'
  >('user-details')

  const tabs: PageTab[] = [
    {
      title: 'User details',
      active: tab === 'user-details',
      onPress: () => setTab('user-details'),
    },
    {
      title: 'Bank accounts',
      active: tab === 'bank-accounts',
      onPress: () => setTab('bank-accounts'),
      amount: `${bankAccounts?.length}`,
    },
    {
      title: 'Wallets',
      active: tab === 'wallets',
      onPress: () => setTab('wallets'),
      amount: `${wallets?.length}`,
    },
    {
      title: client?.role === 'Organization' ? 'KYB' : 'KYC',
      active: tab === 'kyb/kyc',
      onPress: () => setTab('kyb/kyc'),
      notification: client?.kycDetails.kycStatus === 'Completed',
    },
    {
      title: 'Transfers',
      active: tab === 'transfers',
      onPress: () => setTab('transfers'),
      amount: `${swaps?.length}`,
    },
    ...(client?.role === 'Organization'
      ? [
          {
            title: 'Members',
            active: tab === 'members',
            onPress: () => setTab('members'),
            amount: members?.length,
          } as PageTab,
        ]
      : []),
    {
      title: 'Activity',
      active: tab === 'activity',
      onPress: () => setTab('activity'),
      amount: `${activity?.length}`,
    },
  ]

  return (
    <PageWrapper
      title={`${client?.name} `}
      goBack={() => history.goBack()}
      isLoading={isLoading}
      tabs={tabs}
      fixedWidth={tab !== 'transfers'}
    >
      {tab === 'user-details' && (
        <UserDetails client={client} isLoading={isLoading} />
      )}
      {tab === 'bank-accounts' && <BankAccounts bankAccounts={bankAccounts} />}
      {tab === 'wallets' && <Wallets wallets={wallets} />}
      {tab === 'kyb/kyc' && <KYC client={client} />}
      {tab === 'transfers' && <Transfers swaps={swaps} />}
      {tab === 'members' && <Members members={members} />}
      {tab === 'activity' && <Activity activity={activity} />}
    </PageWrapper>
  )
}

export default ClientScreen
