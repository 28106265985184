import React from 'react'
import {
  CurrencyDto,
  NetworkDto,
  NetworkOffRampDto,
} from '../types/coreApi-types'

interface Props {
  src: NetworkDto | CurrencyDto | NetworkOffRampDto | null | undefined
  size: 'base' | 'md'
}

const Icon: React.FC<Props> = ({ src, size }) => {
  const getClass = () => {
    switch (size) {
      case 'base':
        return 'inline-icon'
      case 'md':
        return 'inline-icon-md'
    }
  }
  if (!src) return <></>

  return <img src={src.iconUrl} className={`${getClass()}`} alt={src.name} />
}

export default Icon
