import React from 'react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  MenuProps,
  PlacementWithLogical,
  Portal,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

interface Props {
  children?: React.ReactNode
  renderList?: JSX.Element[] | JSX.Element
  placement?: PlacementWithLogical
  customElement?: React.ReactNode
  portalWrap?: boolean
  maxH?: string
}

const DropdownMenu: React.FC<Props & MenuProps> = ({
  children,
  renderList,
  placement,
  customElement,
  portalWrap,
  maxH,
  ...restProps
}) => {
  const isDarkMode =
    document.body.getAttribute('theme') === 'dark' &&
    localStorage.getItem('color-theme') === 'dark'

  const menuListComponent = (
    <MenuList
      overflowY="auto"
      overflowX={'hidden'}
      maxH={maxH ?? '350px'}
      shadow={isDarkMode ? 'none' : 'menu'}
      border={`1px solid ${isDarkMode ? '#1D2A37' : '#EAEDF3'}`}
      p="6px"
      bg={isDarkMode ? '#1A2128' : '#fff'}
      borderRadius="12px"
    >
      <div>{customElement}</div>

      <div className="space-y-[3px]">{renderList}</div>
    </MenuList>
  )

  return (
    <div>
      <Menu isLazy placement={placement} {...restProps}>
        <MenuButton width={'100%'}>{children}</MenuButton>
        {portalWrap ? <Portal>{menuListComponent}</Portal> : menuListComponent}
      </Menu>
    </div>
  )
}

export default DropdownMenu
