import Navigator from './Navigator'
import './reset.css'
import { Provider } from 'react-redux'
import store from './redux/store'
import { ChakraProvider } from '@chakra-ui/react'
import chakraTheme from './chakraTheme'
import { useEffect, useRef, useState } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import {
  API_URL,
  AUTH0_CALLBACK_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
} from './constants'
import RTKErrorLoggerHooksProvider from './containers/RTKErrorLoggerHooksProvider'
import { MantineProvider } from '@mantine/core'
import mantineTheme from './mantineTheme'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'

function App() {
  useEffect(() => {
    if (localStorage.getItem('color-theme') === 'dark') {
      document.documentElement.classList.add('dark')
      document.body.setAttribute('theme', 'dark')

      document.body.classList.remove('html-light')
      document.body.classList.add('html-dark')
    } else {
      document.documentElement.classList.remove('dark')
      document.body.setAttribute('theme', 'light')
    }
  }, [])

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={AUTH0_CALLBACK_URL}
      audience={API_URL}
    >
      <Provider store={store}>
        <ChakraProvider theme={chakraTheme} resetCSS={true}>
          <MantineProvider theme={mantineTheme}>
            <RTKErrorLoggerHooksProvider>
              <Navigator />
            </RTKErrorLoggerHooksProvider>
          </MantineProvider>
        </ChakraProvider>
      </Provider>
    </Auth0Provider>
  )
}

export default App
