import moment from 'moment'
import React, { useState } from 'react'
import AnimateHeight from '../../../../components/AnimateHeight'
import DefaultModal from '../../../../components/DefaultModal'
import { useGetSwapActivitiesQuery } from '../../../../redux/services/coreApi'
import {
  BankAccountDto,
  SwapDto,
  TransferActivityDto,
} from '../../../../types/coreApi-types'
import getStatusFromSwap from '../../../../utils/getStatusFromSwap'
import { formatNumber } from '../../../../utils/formatters'
import BankInfoContainer from '../Broker Service/components/BankInfoContainer'
import WalletInfoContainer from '../Broker Service/components/WalletInfoContainer'

interface Props {
  isOpen: boolean
  onClose: () => void
  bankAccount?: BankAccountDto
}

const BankInformationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  bankAccount,
}) => {
  return (
    <DefaultModal
      isLoading={false}
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      title="Bank details"
      maxW="700px"
    >
      <BankInfoContainer accountInfo={bankAccount} />

      <div className="p-5">
        <button onClick={onClose} className="button">
          Close
        </button>
      </div>
    </DefaultModal>
  )
}

export default BankInformationModal
