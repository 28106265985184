import { Tooltip } from '@chakra-ui/react'
import {
  faQuestionCircle,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CurrencyDto, NetworkDto } from '../types/coreApi-types'
import DropdownMenu from './DropdownMenu'
import Icon from './Icon'

interface CDropdownMenuProps {
  label?: string
  selectedText?: string
  emptyText: string
  selectedIconSrc?: CurrencyDto | NetworkDto | null
  renderList?: JSX.Element | JSX.Element[]
  tooltip?: string
}

const CDropdownMenu: React.FC<CDropdownMenuProps> = ({
  label,
  selectedText,
  emptyText,
  selectedIconSrc,
  renderList,
  tooltip,
}) => {
  return (
    <>
      {label && (
        <label
          htmlFor={label}
          className="flex text-description items-center mb-2"
        >
          <div>{label}</div>
          {tooltip && (
            <Tooltip label={tooltip}>
              <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
            </Tooltip>
          )}
        </label>
      )}
      <DropdownMenu maxH="310px" renderList={renderList}>
        <div className="input text-start flex items-center pl-3 gap-2">
          <Icon src={selectedIconSrc} size="base" />

          {selectedText}
          {!selectedText && <p className="text-description">{emptyText}</p>}

          <div className="absolute right-0 top-0 bottom-0 flex items-center mr-3 text-brand-light-text-description">
            <FontAwesomeIcon icon={faCaretDown} size="sm" />
          </div>
        </div>
      </DropdownMenu>
    </>
  )
}

export default CDropdownMenu
