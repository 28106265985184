import React from 'react'

interface PenningWebsiteHeaderLinkProps {}

const PenningWebsiteHeaderLink: React.FC<
  PenningWebsiteHeaderLinkProps
> = () => {
  return (
    <div className="absolute top-0 left-0 m-[12px]">
      <a href="https://www.penning.dk">
        <img
          src="https://www.penning.dk/favicon.ico"
          alt="Go back to the main Penning website"
          width="64px"
        />
      </a>
    </div>
  )
}

export default PenningWebsiteHeaderLink
