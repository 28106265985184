import React, { useEffect, useState } from 'react'
import jimmie from '../assets/people/jimmie.png'
import martin from '../assets/people/martin.png'
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import SupportOption from './SupportOption'
import SmartPhone01Icon from '../assets/icons/smart-phone-01-stroke-rounded'
import BubbleChatIcon from '../assets/icons/bubble-chat-stroke-rounded'
import SettingError04Icon from '../assets/icons/setting-error-04-stroke-rounded'

interface NeedHelpProps {
  //company: 'pcm' | 'otc'
}

type ContactDetails = {
  uid: string
  title: string
  name: string
  phone: string
  mail: string
  src: string
  otherQuestions: string
}

const contactDetails = {
  pcm: {
    uid: 'pcm',
    title: 'Capital Management',
    name: 'Martin',
    phone: '+45 30 22 00 33',
    mail: 'm@penning.dk',
    src: martin,
    otherQuestions: 'Need help with our broker or OTC services?',
  },
  otc: {
    uid: 'otc',
    title: 'Broker desk / OTC',
    name: 'Jimmie',
    phone: '+45 30 22 00 33',
    mail: 'j@penning.dk',
    src: jimmie,
    otherQuestions: 'Do you have questions concerning our investment fund?',
  },
}

const NeedHelp: React.FC<NeedHelpProps> = ({}) => {
  const modal = useDisclosure()
  // const [details, setDetails] = useState<ContactDetails>(
  //   contactDetails[company]
  // )

  // useEffect(() => {
  //   setDetails(contactDetails[company])
  // }, [company])

  return (
    <>
      <div
        onClick={modal.onOpen}
        className="bg-brand-app-menu-hover dark:hover:bg-brand-dark-card dark:bg-brand-dark-background hover:bg-brand-app-menu-hover/50 select-none cursor-pointer border border-white/10 border-solid flex gap-2.5 items-center rounded-xl transition-all px-3 py-2.5 mb-2"
      >
        <div className="p-0.5 border border-solid border-white/15 rounded-full">
          <img
            className="w-9 h-9 rounded-full shrink-0"
            src={contactDetails.otc.src}
          />
        </div>

        <div className="text-xs">
          <p className="font-medium text-white/50">Need help?</p>
          <p className="font-semi text-white">
            Contact {contactDetails.otc.name}
          </p>
        </div>
      </div>

      <Modal
        isOpen={modal.isOpen}
        onClose={() => {
          modal.onClose()
          //setDetails(contactDetails[company])
        }}
      >
        <ModalOverlay bg={'rgba(0, 0, 0,0.85)'} />
        <ModalContent rounded="24px" p="0px" maxW={'600px'} marginX="16px">
          <div
            className={`bg-white dark:bg-brand-dark-card rounded-2xl relative overflow-hidden p-12 pt-10`}
          >
            {/* <p className="text-description">{contactDetails.otc.title}</p> */}
            <p className="font-semi text-lg dark:text-white">
              How can we help you?
            </p>
            <div className="grid grid-cols-2 gap-3 mt-10">
              <SupportOption
                title={`Call ${contactDetails.otc.name}`}
                icon={<SmartPhone01Icon className="dark:text-white" />}
                onClick={() =>
                  window.open(`tel:${contactDetails.otc.phone}`, '_blank')
                }
                subtitle={contactDetails.otc.phone}
              />
              <SupportOption
                title={`Send a message`}
                icon={<BubbleChatIcon className="dark:text-white" />}
                onClick={() =>
                  window.open(`mailto:${contactDetails.otc.mail}`, '_blank')
                }
                subtitle={'Reply in under 1 business day'}
              />
            </div>
            <div className="h-px w-full bg-black/5 my-4 dark:bg-white/10" />
            <div
              onClick={() => {
                window.open('mailto:info@penning.dk', '_blank')
              }}
              className="p-5 bg-gray-100 hover:bg-gray-200 dark:bg-brand-dark-background dark:hover:bg-brand-dark-background/50 transition-all rounded-xl gap-5 select-none cursor-pointer flex items-center"
            >
              <SettingError04Icon className="h-6 w-6 dark:text-white" />
              <div>
                <p className="font-semi text-sm dark:text-white">
                  Report an issue{' '}
                </p>
                <p className="text-description">
                  If something isn’t working or doesn’t look correct, let us
                  know.
                </p>
              </div>
            </div>

            {/* <div className="text-description mt-10">
              {details.otherQuestions}{' '}
              <span
                className="hover:underline text-blue-600 dark:text-blue-400 cursor-pointer"
                onClick={() => {
                  modal.onClose()

                  setTimeout(() => {
                    setDetails(
                      details.uid === 'pcm'
                        ? contactDetails.otc
                        : contactDetails.pcm
                    )
                    modal.onOpen()
                  }, 300)
                }}
              >
                Click here
              </span>
            </div> */}
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}

export default NeedHelp
