import React, { useEffect, useState } from 'react'
import DefaultModal from './DefaultModal'
import SegmentTab, { SegmentTabItemProps } from './SegmentTab'
import { useCreateBankAccountMutation } from '../redux/services/coreApi'
import {
  BankAccountDetailsDto,
  BankAccountDetailsType,
} from '../types/coreApi-types'

export type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>
}

const initialBankAccountDetails: Required<
  NonNullableFields<BankAccountDetailsDto>
> = {
  registration: '',
  accountNumber: '',
  bic: '',
  iban: '',
  recipientAddress: '',
  recipientCity: '',
  recipientCountry: '',
  recipientName: '',
  recipientZipCode: '',
}

interface AddBankAccountModalProps {
  isOpen: boolean
  onClose: () => void
}

const AddBankAccountModal: React.FC<AddBankAccountModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [bankAccountDetails, setBankAccountDetails] = useState(
    initialBankAccountDetails
  )
  const [bankAccountDetailsType, setBankAccountDetailsType] =
    useState<BankAccountDetailsType>('DomesticBankAccountDetails')

  const [createBankAccount, { isLoading }] = useCreateBankAccountMutation()

  const onSubmit = async () => {
    await createBankAccount({
      bankAccountDetailsType,
      name,
      description,
      ...bankAccountDetails,
    }).unwrap()
    onClose()
  }

  const tabs: SegmentTabItemProps[] = [
    {
      title: 'Danish',
      isActive: bankAccountDetailsType === 'DomesticBankAccountDetails',
      onClick: () => setBankAccountDetailsType('DomesticBankAccountDetails'),
    },
    {
      title: 'International',
      isActive: bankAccountDetailsType === 'InternationalBankAccountDetails',
      onClick: () =>
        setBankAccountDetailsType('InternationalBankAccountDetails'),
    },
  ]

  useEffect(() => {
    if (isOpen) {
      setBankAccountDetailsType('DomesticBankAccountDetails')
      setBankAccountDetails(initialBankAccountDetails)
    }
  }, [isOpen])

  return (
    <DefaultModal
      isLoading={isLoading}
      isLoadingText="Adding your Bank Account..."
      isOpen={isOpen}
      onClose={onClose}
      title="Add new bank account"
      whiteBG
    >
      <SegmentTab tabs={tabs} />
      <div className="p-5 pt-0 space-y-5 h-[600px] ">
        <div className="max-h-[450px] overflow-y-auto">
          <p className="mb-1 text-description">Name of your bank</p>
          <input
            type="text"
            className="input mb-3"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <p className="mb-1 text-description">Description (optional)</p>
          <input
            type="text"
            className="input mb-3"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          {bankAccountDetailsType === 'DomesticBankAccountDetails' && (
            <>
              <p className="mb-1 text-description">Registration number</p>
              <input
                type="text"
                className="input mb-3"
                value={bankAccountDetails.registration}
                onChange={e =>
                  setBankAccountDetails(bad => ({
                    ...bad,
                    registration: e.target.value,
                  }))
                }
              />
              <p className="mb-1 text-description">Account number</p>
              <input
                type="text"
                className="input"
                value={bankAccountDetails.accountNumber}
                onChange={e =>
                  setBankAccountDetails(bad => ({
                    ...bad,
                    accountNumber: e.target.value,
                  }))
                }
              />
            </>
          )}
          {bankAccountDetailsType === 'InternationalBankAccountDetails' && (
            <>
              <p className="mb-1 text-description">IBAN</p>
              <input
                type="text"
                className="input mb-3"
                value={bankAccountDetails.iban}
                onChange={e =>
                  setBankAccountDetails(bad => ({
                    ...bad,
                    iban: e.target.value,
                  }))
                }
              />
              <p className="mb-1 text-description">BIC</p>
              <input
                type="text"
                className="input mb-3"
                value={bankAccountDetails.bic}
                onChange={e =>
                  setBankAccountDetails(bad => ({
                    ...bad,
                    bic: e.target.value,
                  }))
                }
              />
              <p className="mb-1 text-description">Recipient Name</p>
              <input
                type="text"
                className="input mb-3"
                value={bankAccountDetails.recipientName}
                onChange={e =>
                  setBankAccountDetails(bad => ({
                    ...bad,
                    recipientName: e.target.value,
                  }))
                }
              />
              <p className="mb-1 text-description">Recipient Address</p>
              <input
                type="text"
                className="input mb-3"
                value={bankAccountDetails.recipientAddress}
                onChange={e =>
                  setBankAccountDetails(bad => ({
                    ...bad,
                    recipientAddress: e.target.value,
                  }))
                }
              />
              <p className="mb-1 text-description">Recipient Zip Code</p>
              <input
                type="text"
                className="input mb-3"
                value={bankAccountDetails.recipientZipCode}
                onChange={e =>
                  setBankAccountDetails(bad => ({
                    ...bad,
                    recipientZipCode: e.target.value,
                  }))
                }
              />
              <p className="mb-1 text-description">Recipient City</p>
              <input
                type="text"
                className="input mb-3"
                value={bankAccountDetails.recipientCity}
                onChange={e =>
                  setBankAccountDetails(bad => ({
                    ...bad,
                    recipientCity: e.target.value,
                  }))
                }
              />
              <p className="mb-1 text-description">Recipient Country</p>
              <input
                type="text"
                className="input mb-3"
                value={bankAccountDetails.recipientCountry}
                onChange={e =>
                  setBankAccountDetails(bad => ({
                    ...bad,
                    recipientCountry: e.target.value,
                  }))
                }
              />
            </>
          )}
        </div>

        <button disabled={false} onClick={onSubmit} className="button">
          Submit
        </button>
      </div>
    </DefaultModal>
  )
}

export default AddBankAccountModal
