import {
  SwapDto,
  TransferActivityDto,
  TransferStatus,
} from '../types/coreApi-types'

interface TransactionStatus {
  transferStatus: TransferStatus
  title: string
  description?: string
}

export default function getStatusFromSwap(swap: SwapDto): TransactionStatus {
  //Tjek status for et Swap via banktransfer
  if (swap.deposit) {
    if (swap.deposit.transferStatus === 'Completed') {
      //Er withdraw processing => "Processing"
      if (swap.withdraw?.transferStatus === 'Processing') {
        return {
          transferStatus: 'Processing',
          title: 'Converting your funds',
          description: `We are currently converting your funds to ${swap.withdraw.currency.isoCode}.`,
        }

        //Er withdraw completed => "Completed"
      } else if (swap.withdraw?.transferStatus === 'Completed') {
        return {
          transferStatus: 'Completed',
          title: 'Swap successfully completed',
          description: `Your ${
            swap.withdraw.currency.isoCode
          } has been delivered to your ${
            swap.withdraw.bankAccount ? 'bank account' : 'wallet'
          }.`,
        }
      }
    } else if (swap.deposit.transferStatus === 'Pending') {
      //Er deposit pending => "Pending"
      return {
        transferStatus: 'Pending',
        title: 'Waiting to receive your money',
        description: `Make a transfer to our ${
          swap.deposit.bankAccount ? 'bank' : 'wallet'
        }. Estimated time of confirmation: 1 hour - 1 business day.`,
      }
    } else if (swap.deposit.transferStatus === 'Cancelled') {
      return {
        transferStatus: 'Cancelled',
        title: 'Swap cancelled by Admin',
        description:
          'Your Swap has been Cancelled. If you believe this to be an error, contact the Penning team.',
      }
    }
  }

  //Ved ikke hvordan vi skal håndtere default case for now
  return {
    transferStatus: 'Cancelled',
    title: 'No status',
    description: 'No status description',
  }
}
