import React from 'react'
import './index.css'
import App from './App'
import * as Sentry from '@sentry/react'
import { NODE_ENV, SENTRY_DSN } from './constants'
import { createRoot } from 'react-dom/client'

if (NODE_ENV !== 'development') {
  // Setup Sentry
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Performance monitoring
    tracesSampleRate: 0.05,
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

console.debug('Environment: ' + NODE_ENV)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
