import { UseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { addRtkQueryHandledError } from '../redux/rtkQueryErrorLogger'
import {
  MutationActionCreatorResult,
  MutationDefinition,
} from '@reduxjs/toolkit/query'

export function handledErrorQuery<T extends UseQueryHookResult<any>>(
  fn: () => T
) {
  const queryRes = fn()

  if (queryRes.requestId) {
    addRtkQueryHandledError(queryRes.requestId)
  }

  return queryRes
}

export function handledErrorMutation<T, Y>(
  mutation: MutationActionCreatorResult<
    MutationDefinition<T, any, any, Y, 'coreApi'>
  >
) {
  if (mutation.requestId) {
    addRtkQueryHandledError(mutation.requestId)
  }

  const mutationResPromise = mutation.unwrap()
  return mutationResPromise
}
