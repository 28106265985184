import { Icon, Spinner, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { faCaretDown, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DefaultModal from '../../../../../../components/DefaultModal'
import DropdownCell from '../../../../../../components/DropdownCell'
import DropdownMenu from '../../../../../../components/DropdownMenu'
import { SwapLockedVariable } from '../../../../../../types/coreApi-types'

const lockedVariables: SwapLockedVariable[] = ['AmountIn', 'AmountOut', 'Rate']

interface SummaryRowProps {
  title: string
  value?: string | React.ReactNode
  children?: React.ReactNode
  disabled?: boolean
  editable?: {
    onChangeInput?: React.ChangeEventHandler<HTMLInputElement>
    onChangeTextArea?: React.ChangeEventHandler<HTMLTextAreaElement>
    value: string
    placeholder?: string
    onClickSave: () => Promise<any>
    isLoading: boolean
  }
  modalEditable?: {
    title: string
    description: string
  }
  showLockedVariablePicker?: boolean
  setLockedVariable?: (variable: SwapLockedVariable | null) => void
  lockedVariable?: string | null
}

const SummaryRow: React.FC<SummaryRowProps> = ({
  title,
  value,
  editable,
  children,
  disabled,
  modalEditable,
  showLockedVariablePicker,
  setLockedVariable,
  lockedVariable,
}) => {
  const editModal = useDisclosure()

  return (
    <div className="flex flex-col border-b-[1px] justify-center border-solid border-[#2C383B]/10 text-[13px] min-h-[56px]">
      <div className="flex  justify-between">
        <p
          className={`pl-5 font-semi ${
            children || editable?.onChangeTextArea ? 'pt-4' : 'pt-2'
          }`}
        >
          {title}
        </p>
        {editable ? (
          modalEditable ? (
            <>
              <DefaultModal
                isLoading={false}
                isOpen={editModal.isOpen}
                onClose={() => {
                  editModal.onClose()
                  if (setLockedVariable) {
                    setLockedVariable(null)
                  }
                }}
                whiteBG
                maxW="500px"
              >
                <div className="p-5 text-description-large">
                  <p className="text-xl mb-2">{modalEditable.title}</p>
                  <p className="text-medium">{modalEditable.description}</p>

                  <input
                    onChange={editable.onChangeInput}
                    placeholder={editable.placeholder}
                    type="text"
                    value={editable.value}
                    className="mt-6 input"
                  />

                  {showLockedVariablePicker === true && (
                    <DropdownMenu
                      maxH="310px"
                      renderList={lockedVariables.map(lockedVariable => {
                        return (
                          <DropdownCell
                            key={lockedVariable}
                            title={lockedVariable}
                            onClick={() =>
                              setLockedVariable &&
                              setLockedVariable(lockedVariable)
                            }
                          />
                        )
                      })}
                    >
                      <div className="input text-start flex items-center pl-3 gap-2 mt-6">
                        {lockedVariable ?? 'Select a variable to lock'}

                        <div className="absolute right-0 top-0 bottom-0 flex items-center mr-3 text-brand-light-text-description">
                          <FontAwesomeIcon icon={faCaretDown} size="sm" />
                        </div>
                      </div>
                    </DropdownMenu>
                  )}

                  <div className="flex gap-2 w-full mt-16">
                    <button
                      className="button-secondary"
                      onClick={editModal.onClose}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={showLockedVariablePicker && !lockedVariable}
                      className="button"
                      onClick={async () => {
                        await editable.onClickSave()
                        editModal.onClose()
                      }}
                    >
                      {editable.isLoading ? <Spinner size="xs" /> : 'Save'}{' '}
                    </button>
                  </div>
                </div>
              </DefaultModal>

              <div className="flex items-center px-5">
                <div className="pr-4 font-normal text-brand-light-text-description">
                  {value}
                </div>

                <button
                  disabled={disabled}
                  onClick={editModal.onOpen}
                  className="bg-brand-app-menu text-white h-[32px] px-5 transition-all rounded disabled:cursor-not-allowed disabled:opacity-50"
                >
                  <FontAwesomeIcon icon={faPenToSquare} className="text-sm" />
                </button>
              </div>
            </>
          ) : (
            <div className="flex items-center gap-2 px-5 font-medium">
              {editable.onChangeInput && (
                <input
                  onChange={editable.onChangeInput}
                  placeholder={editable.placeholder}
                  type="text"
                  name="hash"
                  id="hash"
                  value={editable.value}
                  className="border-0 text-xs outline-0 bg-white h-[32px] rounded focus:outline-0"
                />
              )}

              {editable.onChangeTextArea && (
                <textarea
                  value={editable.value}
                  onChange={editable.onChangeTextArea}
                  placeholder={editable.placeholder}
                  className="border-0 text-xs outline-0 bg-white min-h-[50px] rounded focus:outline-0 my-2"
                />
              )}

              <button
                disabled={disabled}
                onClick={editable.onClickSave}
                className="bg-brand-app-menu text-white h-[32px] px-5 transition-all rounded disabled:cursor-not-allowed disabled:opacity-50"
              >
                {editable.isLoading ? <Spinner size="sm" /> : 'Save'}{' '}
              </button>
            </div>
          )
        ) : (
          <p className="pr-5 font-normal text-brand-light-text-description">
            {value}
          </p>
        )}
      </div>
      {children && <div className="px-5 pt-2 pb-4">{children}</div>}
    </div>
  )
}

export default SummaryRow
