import moment from 'moment'
import React from 'react'
import Icon from '../../../../../components/Icon'
import Tooltip from '../../../../../components/Tooltip'
import TransferStatusBadge from '../../../../../components/TransferStatusBadge'
import { SwapDto } from '../../../../../types/coreApi-types'
import formatWalletAdress from '../../../../../utils/formatWalletAddress'
import getStatusFromSwap from '../../../../../utils/getStatusFromSwap'
import { formatNumber, formatUtcDate } from '../../../../../utils/formatters'

interface TableRowProps {
  swap: SwapDto
  onClick?: () => void
}

const TableRow: React.FC<TableRowProps> = ({ swap, onClick }) => {
  const isWalletTransfer = Boolean(swap.withdraw?.wallet)
  const isBankTransfer = Boolean(swap.withdraw?.bankAccount)

  const swapWithdrawNetwork = swap.withdraw?.wallet?.network
  const bankAccountDetails = swap.withdraw?.bankAccount?.bankAccountDetails

  const transactionStatus = getStatusFromSwap(swap)

  return (
    <div
      onClick={onClick}
      className="w-full cursor-pointer grid grid-cols-6 hover:bg-brand-light-background dark:hover:bg-brand-dark-background  border-[1px] px-6 py-3"
    >
      <p className="cell">{formatUtcDate(swap.createdAt)}</p>
      <Tooltip
        label={
          <div>
            <TransferStatusBadge
              transferStatus={transactionStatus.transferStatus}
            />

            <div className="mt-2">
              <p>{transactionStatus.title}</p>
              <p className="text-description">
                {transactionStatus.description}
              </p>
            </div>
          </div>
        }
      >
        <div className="cell">
          <TransferStatusBadge
            transferStatus={getStatusFromSwap(swap).transferStatus}
          />
        </div>
      </Tooltip>

      {swap.deposit && swap.withdraw && (
        <>
          <p className="cell">
            {formatNumber(swap.deposit.amount, swap.deposit.currency)}
          </p>
          <p className="cell hidden lg:flex">
            {formatNumber(swap.withdraw.amount, swap.withdraw.currency)}
          </p>
        </>
      )}

      {isWalletTransfer && (
        <Tooltip
          label={
            <div className="flex items-center gap-3">
              <Icon size="md" src={swapWithdrawNetwork} />

              <div>
                <p>{swap.withdraw?.wallet?.address}</p>
                <p className="text-description">
                  {swap.withdraw?.wallet?.network?.name}
                </p>
              </div>
            </div>
          }
        >
          <p className="cell hidden lg:flex gap-2">
            <Icon size="base" src={swapWithdrawNetwork} />

            {formatWalletAdress(swap.withdraw?.wallet?.address)}
          </p>
        </Tooltip>
      )}
      {isBankTransfer && (
        <Tooltip
          label={
            <div className="flex items-center gap-3">
              <div>
                <p>
                  {bankAccountDetails?.accountNumber ||
                    bankAccountDetails?.iban}
                </p>
                <p className="text-description">
                  {bankAccountDetails?.registration || bankAccountDetails?.bic}
                </p>
              </div>
            </div>
          }
        >
          <p className="cell hidden lg:flex gap-2">
            {bankAccountDetails?.accountNumber || bankAccountDetails?.iban}
          </p>
        </Tooltip>
      )}

      {swapWithdrawNetwork && (
        <p className="cell hidden lg:flex gap-2">
          <Icon size="base" src={swapWithdrawNetwork} />

          {swapWithdrawNetwork.name}
        </p>
      )}
      {isBankTransfer && (
        <p className="cell hidden lg:flex gap-2">
          {swap.withdraw?.bankAccount?.name}
        </p>
      )}
    </div>
  )
}

export default TableRow
