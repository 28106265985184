import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { HTMLProps } from 'react'

export type StepState = 'completed' | 'in-progress' | 'pending'

interface StepProps {
  step: number
  title: string
  description: string | React.ReactNode
  status: StepState
  children?: React.ReactNode
}

const Step: React.FC<StepProps> = ({
  step,
  title,
  description,
  status,
  children,
}) => {
  const statusStyle = () => {
    switch (status) {
      case 'in-progress':
        return ''
      case 'completed':
        return 'line-through opacity-50 text-brand-light-text-description'

      case 'pending':
        return 'opacity-40'
    }
  }

  return (
    <div className="flex items-start justify-between">
      <div className="flex items-start gap-4">
        <div className="w-10 h-10 shrink-0 ">
          {status === 'completed' ? (
            <div className="w-full h-full flex items-center justify-center">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-[#2E9E6B] h-5 w-5"
              />
            </div>
          ) : (
            <div
              className={`w-full h-full border-[1px] ${
                status === 'in-progress'
                  ? 'border-black/10 dark:border-white/20 border-solid'
                  : ''
              } rounded-full bg-slate-100 dark:bg-brand-dark-background flex items-center justify-center font-semi ${statusStyle()}`}
            >
              {step}
            </div>
          )}
        </div>
        <div className={`${statusStyle()}`}>
          <p className="font-semi text-[0.80rem]">{title}</p>
          <p className="text-description">{description}</p>
        </div>
      </div>
      {status === 'in-progress' && <div className="ml-5">{children}</div>}
    </div>
  )
}

export default Step
