import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import {
  useCreateKycCaseMutation,
  useGetCountriesQuery,
  useGetMembersQuery,
  useGetUltimateBeneficialOwnersQuery,
  useLinkUltimateBeneficialOwnersMutation,
} from '../../../../redux/services/coreApi'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import KycPageWrapper from './components/KycPageWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faCaretDown,
  faLink,
  faLinkSlash,
  faPlus,
  faPlusCircle,
  faUserCircle,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons'
import AnimateHeight from '../../../../components/AnimateHeight'
import { Spinner, useDisclosure, useToast } from '@chakra-ui/react'
import {
  faQuestionCircle,
  faTrashCan,
} from '@fortawesome/free-regular-svg-icons'
import Tooltip from '../../../../components/Tooltip'
import DropdownMenu from '../../../../components/DropdownMenu'
import DropdownCell from '../../../../components/DropdownCell'
import {
  LinkUltimateBeneficialOwnersItemCommand,
  UserDto,
} from '../../../../types/coreApi-types'
import FormCheckbox from '../../../../components/FormCheckbox'
import DefaultModal from '../../../../components/DefaultModal'
import Toast from '../../../../components/Toast'
import FormSelect from '../../../../components/FormSelect'
import InfoBox from '../../../../components/InfoBox'

interface KybUboScreenProps {}

const Divider: React.FC = () => {
  return <div className="h-[1px] bg-black/5 -mx-5 my-4 dark:bg-white/5" />
}

type UboDataState = {
  fullName?: string
  email?: string
  isOriginalUbo?: boolean
  memberLink?: UserDto
  emailLink?: string
  originalName?: string
  country?: string
  companyShare?: string
}

const KybUboScreen: React.FC<KybUboScreenProps> = () => {
  const history = useHistory()
  const toast = useToast()

  const user = useSelector((state: RootState) => state.session.user)
  const { data: countries } = useGetCountriesQuery()
  const [createKycCase, { isLoading: isCreatingKycCase }] =
    useCreateKycCaseMutation()
  const {
    data: ubos,
    isLoading: isLoadingUbos,
    isSuccess,
  } = useGetUltimateBeneficialOwnersQuery()
  const members = useGetMembersQuery(null, {
    skip: user.role !== 'Organization',
  })

  const [linkUltimateBeneficialOwners, { isLoading: isLinkingUBOs }] =
    useLinkUltimateBeneficialOwnersMutation()

  const [inputPopup, setInputPopup] = useState<{
    open: number
    value: string
  }>({ open: -1, value: '' })

  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [missingData, setMissingData] = useState<boolean>(false)
  const confirmModal = useDisclosure()

  const [uboData, setUboData] = useState<UboDataState[]>([])

  useEffect(() => {
    if (ubos && ubos?.length > 0) {
      let temp: UboDataState[] = []
      ubos.forEach(el => {
        temp.push({
          fullName: el.name,
          email: '',
          isOriginalUbo: true,
          originalName: el.name,
          country: undefined,
          memberLink: undefined,
          companyShare: '',
        })
      })
      setUboData(temp)
    }

    if (ubos && ubos.length === 0) {
      setUboData([
        { fullName: '', email: '', isOriginalUbo: false, companyShare: '' },
      ])
    }

    //Make banner that says we didn't find any UBO's and they must add them themselves!
  }, [ubos, members])

  const handleChange = (
    id: number,
    key: keyof UboDataState,
    newValue: string
  ) => {
    let temp = [...uboData]
    temp[id] = { ...temp[id], [key]: newValue }
    setUboData(temp)
  }

  const linkUBOs = async () => {
    let links: LinkUltimateBeneficialOwnersItemCommand[] = []

    uboData.forEach(el => {
      const isoCode = countries?.find(
        c => c.name === (el.memberLink?.country?.name ?? el.country)
      )?.isoCode
      if (!isoCode || !el.companyShare) return

      if (el.memberLink) {
        links.push({
          memberId: el.memberLink.id,
          isoCode: isoCode,
          sharePercentageOfCompany: parseFloat(el.companyShare),
        })
      } else {
        links.push({
          email: el.emailLink,
          name: el.fullName,
          isoCode: isoCode,
          sharePercentageOfCompany: parseFloat(el.companyShare),
        })
      }
    })

    try {
      const members = await linkUltimateBeneficialOwners({ links }).unwrap()

      const kycCasePromises: Promise<any>[] = []

      members.forEach(({ id }) => {
        kycCasePromises.push(createKycCase({ memberId: id }))
      })

      const res = await Promise.all(kycCasePromises)

      toast({
        position: 'bottom-right',
        duration: 6000,
        render: () => {
          return (
            <Toast type="success">
              You have completed your KYB successfully! The UBOs in your
              organization have received an email.
            </Toast>
          )
        },
      })

      history.push('/app/get-started')
    } catch (error) {
      console.debug(error)

      let errorMsg = 'Somewthing went wrong'

      if ((error as any)?.data?.errors) {
        errorMsg = (error as any)?.data?.errors
      }

      toast({
        position: 'bottom-right',
        duration: 6000,
        render: () => {
          return <Toast type="error">{errorMsg}</Toast>
        },
      })

      confirmModal.onClose()
    }
  }

  const canCreateEmailLink = uboData.some(
    el => el.emailLink === inputPopup.value
  )
    ? true
    : false || members.data?.some(el => el.email === inputPopup.value)
    ? true
    : false
  const isEmailValid = inputPopup.value.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

  if (user.role !== 'Organization') return <Redirect to={'/kyc/start'} />

  return (
    <KycPageWrapper>
      <div className="bg-white dark:bg-brand-dark-card flex p-5 flex-col w-full rounded-xl max-w-[1000px]">
        <div className="flex items-center gap-2 mb-4">
          <FontAwesomeIcon
            icon={faUserShield}
            className="text-brand-blue text-md dark:text-white"
          />
          <h1 className="font-semi text-sm text-brand-light-text-default dark:text-white">
            Enter Ultimate Beneficial Owners (UBO)
          </h1>
        </div>
        <p className="text-description">
          List all people which holds a minimum of 10-25 % of capital or voting
          rights in your organization. Write their email and what member they
          should be linked to. If they don’t have a Penning account, you can add
          them by entering their email.
        </p>
        <Divider />
        <AnimateHeight isVisible={isLoadingUbos}>
          <div className="w-full flex justify-center py-5">
            <Spinner />
          </div>
        </AnimateHeight>

        <AnimateHeight isVisible={isSuccess}>
          {ubos && ubos.length > 0 ? (
            <InfoBox colorScheme="green" className="mb-4 w-min">
              We found {ubos?.length} UBOs in your organisation. Please be sure
              all has been added before you complete the KYB process.
            </InfoBox>
          ) : (
            <InfoBox colorScheme="gray" className="mb-4 w-min">
              We couldn't find any data on your organization, please add them
              manually.
            </InfoBox>
          )}

          <div className="grid grid-cols-8 gap-2 text-description mb-2">
            <div className="col-span-2">Full name</div>
            <div className="col-span-2 flex items-center gap-1">
              Email
              {/* <Tooltip label="This will explain the email column">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip> */}
            </div>

            <div className="col-span-2 flex items-center gap-1">
              Country
              {/* <Tooltip label="This will explain the country column">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip> */}
            </div>
            <div className="col-span-1 flex items-center gap-1 flex-shrink-0 whitespace-nowrap">
              Ownership (%)
              <Tooltip label="Must be a number between 0 and 100">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            </div>
          </div>

          {[
            uboData.map((item, i) => {
              return (
                <div key={i} className="grid grid-cols-8 gap-1 mb-1 ">
                  <input
                    type="text"
                    className="input col-span-2 disabled:text-brand-light-text-description disabled:cursor-not-allowed"
                    value={item.fullName}
                    onChange={e => handleChange(i, 'fullName', e.target.value)}
                    disabled={item.isOriginalUbo || !!item.memberLink}
                  />
                  <div className="col-span-2 flex gap-1 items-center">
                    <div className="w-full">
                      <DropdownMenu
                        renderList={
                          <>
                            <DropdownCell
                              title={'Enter email'}
                              subtitle="For UBOs without a Penning account"
                              isActive={false}
                              onClick={() => {
                                setInputPopup({
                                  ...inputPopup,
                                  open: i,
                                })
                              }}
                              customButton={
                                uboData[i].emailLink ? (
                                  <div
                                    className="cursor-pointer bg-white"
                                    onClick={e => {
                                      e.stopPropagation()
                                      let temp = [...uboData]
                                      temp[i] = {
                                        ...temp[i],
                                        emailLink: undefined,
                                      }
                                      setUboData(temp)
                                    }}
                                  >
                                    <Tooltip label="Unlink email">
                                      <FontAwesomeIcon
                                        icon={faLinkSlash}
                                        className="text-sm dark:text-white"
                                      />
                                    </Tooltip>
                                  </div>
                                ) : undefined
                              }
                              icon={
                                <FontAwesomeIcon
                                  icon={faPlusCircle}
                                  className="text-brand-accent dark:text-white text-lg"
                                />
                              }
                            />
                            {members?.data && members.data.length > 0
                              ? members.data.map((item, idx) => {
                                  return (
                                    <DropdownCell
                                      key={item.email}
                                      title={item.email}
                                      subtitle={item.name}
                                      disabled={
                                        uboData.find(
                                          el => el.memberLink === item
                                        )
                                          ? true
                                          : false
                                      }
                                      isActive={false}
                                      onClick={() => {
                                        let tempArray = [...uboData]
                                        let tempItem = tempArray[i]

                                        tempItem.memberLink = item
                                        tempItem.emailLink = undefined

                                        if (!tempItem.isOriginalUbo) {
                                          tempItem.fullName = item.name
                                        }

                                        tempArray[i] = tempItem

                                        setUboData(tempArray)
                                      }}
                                      icon={
                                        <FontAwesomeIcon
                                          icon={faUserCircle}
                                          className="text-brand-accent dark:text-white text-lg"
                                        />
                                      }
                                      customButton={
                                        uboData[i].memberLink === item ? (
                                          <div
                                            className="cursor-pointer"
                                            onClick={e => {
                                              e.stopPropagation()
                                              let temp = [...uboData]
                                              let tempItem = temp[i]
                                              tempItem.memberLink = undefined
                                              tempItem.fullName = ''
                                              tempItem.country = ''

                                              if (tempItem.isOriginalUbo) {
                                                tempItem.fullName =
                                                  tempItem.originalName
                                              }

                                              temp[i] = tempItem
                                              setUboData(temp)
                                            }}
                                          >
                                            <Tooltip label="Unlink member">
                                              <FontAwesomeIcon
                                                icon={faLinkSlash}
                                                className="text-sm dark:text-white"
                                              />
                                            </Tooltip>
                                          </div>
                                        ) : undefined
                                      }
                                    />
                                  )
                                })
                              : undefined}
                          </>
                        }
                      >
                        <div className="input relative flex items-end">
                          <div className="absolute flex items-center justify-between px-3 inset-0 ">
                            <div className="flex items-center gap-1">
                              {item.memberLink && (
                                <FontAwesomeIcon
                                  icon={faUserCircle}
                                  className="text-sm"
                                />
                              )}
                              <p>{item.memberLink?.email ?? item.emailLink}</p>
                            </div>

                            <FontAwesomeIcon
                              icon={faCaretDown}
                              className="text-xs"
                            />
                          </div>

                          <DropdownMenu
                            portalWrap
                            children
                            isOpen={inputPopup.open === i}
                            closeOnSelect={true}
                            customElement={
                              <div>
                                <input
                                  type="text"
                                  autoFocus
                                  onKeyDown={e => {
                                    if (
                                      e.key === '13' ||
                                      (e.keyCode === 13 && isEmailValid)
                                    ) {
                                      e.stopPropagation()

                                      let temp = [...uboData]
                                      temp[i] = {
                                        ...temp[i],
                                        memberLink: undefined,
                                        emailLink: inputPopup.value,
                                      }
                                      setUboData(temp)
                                      setInputPopup({ open: -1, value: '' })
                                    } else {
                                      e.stopPropagation()
                                    }
                                  }}
                                  value={inputPopup.value}
                                  onChange={e =>
                                    setInputPopup({
                                      ...inputPopup,
                                      value: e.target.value,
                                    })
                                  }
                                  placeholder={'Enter an email'}
                                  className="input cursor-text min-w-[300px]"
                                  onFocus={e => {
                                    e.stopPropagation()
                                    setInputPopup({ ...inputPopup, open: i })
                                  }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    setInputPopup({ ...inputPopup, open: i })
                                  }}
                                />

                                <AnimateHeight
                                  isVisible={canCreateEmailLink}
                                  delayInMS={100}
                                >
                                  <div
                                    className="text-xs font-medium p-2 text-brand-light-red  mt-2 mb-1 rounded bg-brand-light-red-tint dark:bg-brand-dark-red dark:text-white"
                                    onClick={e => e.stopPropagation()}
                                  >
                                    This email already exists in your
                                    organization.
                                  </div>
                                </AnimateHeight>

                                <div className="mt-1 flex gap-1">
                                  <div
                                    className="tinted-button gap-2"
                                    onClick={() =>
                                      setInputPopup({
                                        ...inputPopup,
                                        open: -1,
                                        value: '',
                                      })
                                    }
                                  >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                    Back
                                  </div>
                                  <button
                                    className="tinted-button w-full gap-1.5 disabled:opacity-50 disabled:cursor-not-allowed"
                                    disabled={
                                      !isEmailValid || canCreateEmailLink
                                    }
                                    onClick={e => {
                                      e.stopPropagation()
                                      let temp = [...uboData]
                                      temp[i] = {
                                        ...temp[i],
                                        memberLink: undefined,
                                        emailLink: inputPopup.value,
                                      }
                                      setUboData(temp)
                                      setInputPopup({ open: -1, value: '' })
                                    }}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            }
                          />
                        </div>
                      </DropdownMenu>
                    </div>
                  </div>

                  <div className="col-span-2 flex gap-1">
                    <select
                      placeholder="Select"
                      value={item.memberLink?.country?.name ?? item.country}
                      disabled={!!item.memberLink?.country?.name ?? false}
                      defaultValue=""
                      onChange={e => {
                        let tempArray = [...uboData]
                        let tempItem = tempArray[i]

                        tempItem.country = e.target.value

                        tempArray[i] = tempItem
                        setUboData(tempArray)
                      }}
                      className="input cursor-pointer disabled:cursor-not-allowed"
                    >
                      <option value="" disabled></option>
                      {countries
                        ?.map(item => item.name)
                        .map((option, idx) => {
                          return (
                            <option value={option} key={idx}>
                              {option}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                  <div className="col-span-2 flex gap-1 ">
                    <input
                      type="text"
                      className="input w-full disabled:text-brand-light-text-description disabled:cursor-not-allowed"
                      value={item.companyShare}
                      onChange={e =>
                        handleChange(i, 'companyShare', e.target.value)
                      }
                    />

                    <div
                      onClick={() => {
                        let temp = [...uboData]
                        temp.splice(i, 1)
                        setUboData(temp)
                      }}
                      className="h-[42px] w-[42px] shrink-0 rounded-md cursor-pointer flex items-center justify-center bg-brand-light-background"
                    >
                      <FontAwesomeIcon icon={faTrashCan} className="text-sm" />
                    </div>
                  </div>
                </div>
              )
            }),
          ]}

          <div>
            <div
              className="mt-2 py-2 px-2 rounded cursor-pointer gap-2 hover:bg-brand-light-background font-semi text-xs inline-block transition-all "
              onClick={() => {
                setIsChecked(false)
                setUboData([
                  ...uboData,
                  { fullName: '', email: '', isOriginalUbo: false },
                ])
              }}
            >
              {' '}
              <FontAwesomeIcon icon={faPlus} className="text-xs" /> Add UBO
            </div>
          </div>
        </AnimateHeight>

        <Divider />
        <div className="">
          <FormCheckbox
            id="kyb-checkbox"
            renderLabel={
              'I hereby acknowledge that the list of Ultimate Beneficial Owners is complete.'
            }
            checked={isChecked}
            onChange={() => {
              setIsChecked(!isChecked)
            }}
          />
          <AnimateHeight isVisible={missingData}>
            <InfoBox colorScheme="red" className="mt-3 w-min">
              You are missing some fields, fill them out before continuing.
            </InfoBox>
          </AnimateHeight>
          <AnimateHeight isVisible={isChecked}>
            <button
              className="button mt-3"
              onClick={async () => {
                if (
                  uboData.find(
                    el =>
                      (el.emailLink === undefined &&
                        el.memberLink === undefined) ||
                      el.fullName === '' ||
                      (el.country === undefined &&
                        el.memberLink?.country?.name === undefined) ||
                      el.companyShare === undefined ||
                      el.companyShare === '' ||
                      isNaN(parseFloat(el.companyShare)) ||
                      parseFloat(el.companyShare) > 100 ||
                      parseFloat(el.companyShare) < 0
                  )
                ) {
                  setMissingData(true)
                  setIsChecked(false)
                  return
                } else {
                  setMissingData(false)
                }
                confirmModal.onOpen()
              }}
            >
              Send {uboData.length}{' '}
              {uboData.length === 1 ? 'invite' : 'invites'}
            </button>
          </AnimateHeight>
          <DefaultModal
            title="Are you sure?"
            isOpen={confirmModal.isOpen}
            isLoading={false}
            onClose={confirmModal.onClose}
            whiteBG
          >
            <div className="flex flex-col mx-5">
              <p className="text-description text-center">
                Are you sure you want to send KYC requests to the{' '}
                {uboData.length} UBO's with instructions to complete their
                individual KYC process?
              </p>

              <div
                className="text-xs font-medium p-4 text-brand-light-orange inline-block mt-3 mb-1 rounded bg-brand-light-orange-tint dark:bg-brand-dark-orange dark:text-white"
                onClick={e => e.stopPropagation()}
              >
                <p className="font-semi text-left">Important</p>
                <ul className="list-disc ml-5 mt-1">
                  <li>
                    Be sure you have entered the emails and names correctly.
                  </li>
                  <li>
                    The action cannot be undone. If you add another UBO
                    afterwards, your account will be frozen until the UBO has
                    completed their KYC process.
                  </li>
                </ul>
              </div>

              <div className="mt-10 mb-5 flex gap-1 w-1/2 self-end">
                <button
                  className="button-secondary"
                  onClick={confirmModal.onClose}
                >
                  Cancel
                </button>
                <button
                  className="button whitespace-nowrap px-4"
                  onClick={() => {
                    linkUBOs()
                  }}
                >
                  {isLinkingUBOs || isCreatingKycCase ? (
                    <Spinner size="sm" />
                  ) : (
                    `Send ${uboData.length} invites`
                  )}
                </button>
              </div>
            </div>
          </DefaultModal>
        </div>
      </div>
    </KycPageWrapper>
  )
}

export default KybUboScreen
