import React, { useState } from 'react'
import PageWrapper from '../../../../components/Pagewrapper'
import { PageTab } from '../../../../types/internal-types'
import OverviewScreen from './OverviewScreen'
import ProfileScreen from './ProfileScreen'
import SettingsScreen from './SettingsScreen'

function AccountScreen() {
  const [tab, setTab] = useState<'profile' | 'settings'>('profile')

  const tabs: PageTab[] = [
    // {
    //   title: 'Overview',
    //   onPress: () => setTab('overview'),
    //   active: tab === 'overview',
    // },
    {
      title: 'Profile',
      onPress: () => setTab('profile'),
      active: tab === 'profile',
    },
    {
      title: 'Settings',
      onPress: () => setTab('settings'),
      active: tab === 'settings',
    },
  ]

  return (
    <PageWrapper
      title="Account"
      tabs={tabs}
      fixedWidth
      description="Change your settings and personal details."
    >
      {/* {tab === 'overview' && <OverviewScreen />} */}
      {tab === 'profile' && <ProfileScreen />}
      {tab === 'settings' && <SettingsScreen />}
    </PageWrapper>
  )
}

export default AccountScreen
