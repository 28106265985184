import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { CurrencyDto } from '../../../../../types/coreApi-types'
import { formatNumber } from '../../../../../utils/formatters'

interface SwapSummaryContainerProps {
  payAmount: number
  payCurrency: CurrencyDto
  receiveAmount: number
  receiveCurrency: CurrencyDto
  baseFeeRate: number
  bankFeeRate: number
}

const SwapSummaryContainer: React.FC<SwapSummaryContainerProps> = ({
  payAmount,
  payCurrency,
  receiveAmount,
  receiveCurrency,
  baseFeeRate,
  bankFeeRate,
}) => {
  return (
    <>
      <div className="bg-brand-light-card dark:bg-brand-dark-card flex justify-between p-5 rounded-md">
        <div>
          <p className="text-default-large">
            {formatNumber(payAmount, payCurrency)}
          </p>
          <p className="text-description">You pay</p>
        </div>
        <div className="flex items-center color-text-default">
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
        <div className="text-end">
          <div className="text-default-large">
            <p>{formatNumber(receiveAmount, receiveCurrency)}</p> <span></span>
          </div>

          <p className="text-description">You receive</p>
        </div>
      </div>

      <div className="flex items-center justify-between mx-1 mt-2.5 text-description">
        <p>Processing fee, {(baseFeeRate * 100).toFixed(2)}%</p>
        <p>
          {isNaN(baseFeeRate)
            ? 0
            : formatNumber(baseFeeRate * payAmount, payCurrency)}
        </p>
      </div>

      {bankFeeRate > 0 && (
        <div className="flex items-center justify-between mx-1 mt-2.5 text-description">
          <p>Bank fee, {(bankFeeRate * 100).toFixed(2)}%</p>
          <p>
            {isNaN(bankFeeRate)
              ? 0
              : formatNumber(bankFeeRate * payAmount, payCurrency)}
          </p>
        </div>
      )}
    </>
  )
}

export default SwapSummaryContainer
