import React from 'react'

interface KycPageWrapperProps {
  children?: JSX.Element | JSX.Element[]
}

const KycPageWrapper: React.FC<KycPageWrapperProps> = ({ children }) => {
  return (
    <div className="h-full w-full flex flex-col py-20 items-center overflow-y-auto px-5 xl:px-0">
      {/* <main className="h-[600px] w-[600px] bg-white rounded-[30px] p-[30px] flex justify-center items-center flex-col text-center"> */}
      {children}
      {/* </main> */}
    </div>
  )
}

export default KycPageWrapper
