import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { MenuItem } from '@chakra-ui/react'
import Icon from './Icon'
import {
  CurrencyDto,
  NetworkDto,
  NetworkOffRampDto,
} from '../types/coreApi-types'

interface Props {
  onClick: () => void
  title: string
  subtitle?: string
  isActive?: boolean
  imageName?: CurrencyDto | NetworkDto | NetworkOffRampDto | null
  icon?: JSX.Element
  disabled?: boolean
  customButton?: JSX.Element
}

const DropdownCell: React.FC<Props> = ({
  onClick,
  title,
  subtitle,
  isActive,
  imageName,
  icon,
  disabled,
  customButton,
}) => {
  const isDarkMode =
    document.body.getAttribute('theme') === 'dark' &&
    localStorage.getItem('color-theme') === 'dark'

  return (
    <MenuItem
      onClick={() => {
        if (!disabled) onClick()
      }}
      _focus={{ bg: 'transparent' }}
      _hover={{ bg: isDarkMode ? '#14191F' : '#F2F4F6' }}
      className={`flex w-full items-center justify-between p-0 dark:bg-brand-dark-card hover:bg-brand-light-card-border z-50 dark:hover:bg-brand-dark-background group rounded-lg transition-all cursor-pointer border-2 border-solid ${
        isActive
          ? 'border-2 border-brand-accent border-solid'
          : 'border-transparent'
      }`}
      style={
        disabled
          ? {
              cursor: 'not-allowed',
            }
          : {}
      }
      disabled={disabled}
    >
      <div
        className="flex items-center gap-3 mr-10"
        style={
          disabled
            ? {
                filter: 'opacity(0.5) grayscale(1)',
              }
            : {}
        }
      >
        {imageName && <Icon src={imageName} size="md" />}
        {icon}

        <div className="h-[32px] flex flex-col justify-center">
          <p className="text-brand-light-text-default dark:text-white font-semi text-[12px] ">
            {title}
          </p>
          {subtitle && <p className="text-description-subtle">{subtitle}</p>}
        </div>
      </div>
      {isActive && (
        <FontAwesomeIcon
          icon={faCheck}
          className="text-xs text-brand-accent mr-1.5"
        />
      )}
      {customButton}
    </MenuItem>
  )
}

export default DropdownCell
