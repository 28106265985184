import { Spinner } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { endSession, setUser, startSession } from '../redux/slices/sessionSlice'
import { RootState } from '../redux/store'
import { useLazyGetUserQuery } from '../redux/services/coreApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { Role } from '../types/coreApi-types'
import { AUTH0_LOGOUT_URL } from '../constants'

const AppAuthorizationChecker: React.FC<{
  children?: JSX.Element | (JSX.Element | boolean)[] | boolean
}> = ({ children }) => {
  const {
    user,
    isAuthenticated,
    isLoading: isLoadingAuth0,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
    error,
    loginWithRedirect,
  } = useAuth0()
  const dispatch = useDispatch()
  const isLoadingSession = useSelector(
    (state: RootState) => state.session.isLoading
  )
  const sessionId = useSelector((state: RootState) => state.session.id)

  const [getUserInfo] = useLazyGetUserQuery()

  // Weird Invalid State Auth0 error that happens if user tries to log in
  // automatically after having been inactive for some time - it can be fixed by
  // issuing a new login request
  useEffect(() => {
    if (error && error.message === 'Invalid state') {
      loginWithRedirect()
    }
  }, [error])

  useEffect(() => {
    const populateAppSessionWithAuth0 = async () => {
      const token = await getAccessTokenSilently()
      const idTokenClaims = (await getIdTokenClaims()) as any
      const id = idTokenClaims['coreApi/id']
      const role = idTokenClaims['coreApi/role'] as Role
      const auth0Id = idTokenClaims.sub

      if (id && auth0Id && user) {
        dispatch(
          startSession({
            token,
            id,
            auth0Id,
            role,
            auth0user: user,
          })
        )
      } else {
        window.alert('Something went wrong, logging you out.')
        logout({ returnTo: AUTH0_LOGOUT_URL })
        dispatch(endSession())
      }
    }

    if (!isLoadingAuth0 && user) {
      populateAppSessionWithAuth0()
    }
  }, [isLoadingAuth0, user, getAccessTokenSilently])

  useEffect(() => {
    const getUser = async () => {
      if (sessionId) {
        let user = await getUserInfo(sessionId).unwrap()
        dispatch(setUser({ user }))
      }
    }
    getUser()
  }, [sessionId])

  if (!isLoadingAuth0 && !isAuthenticated) {
    return (
      <div className="p-10 h-screen flex flex-col items-center justify-center bg-brand-light-background dark:bg-brand-dark-background text-brand-light-text-default dark:text-white text-center">
        <FontAwesomeIcon icon={faBan} className="text-3xl mb-4 " />
        <h1 className="font-semi text-4xl ">Unauthorized Access</h1>
        <p className="text-description mt-5 max-w-[600px] text-center">
          It looks like you are not logged into the system, and are thus not
          authorized to use the application. Please try to log out completely
          and log in again if this seems like a mistake.
        </p>
        <div className="w-[200px] mt-10">
          <button
            onClick={() => logout({ returnTo: AUTH0_LOGOUT_URL })}
            className="button"
          >
            Log out
          </button>
        </div>
      </div>
    )
  }

  if (isLoadingSession) {
    return (
      <div className="w-screen h-screen flex justify-center items-center dark:bg-brand-dark-background text-brand-accent dark:text-white">
        <Spinner size="xl" />
        {/* <button onClick={() => logout()}>logout</button> */}
      </div>
    )
  }

  return <>{children}</>
}

export default AppAuthorizationChecker
