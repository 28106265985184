import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import DefaultModal from './DefaultModal'
import { Spinner, useDisclosure, useToast } from '@chakra-ui/react'
import FormInput from './FormInput'
import {
  useCreateCurrencyMutation,
  useGetBankAccountsQuery,
  useGetNetworksQuery,
  useGetWalletsQuery,
  useLazyGetExchangeRateRawCmcInEurQuery,
  useUploadCurrencyIconMutation,
} from '../redux/services/coreApi'
import SegmentTab from './SegmentTab'
import {
  BankAccountDto,
  CurrencyDto,
  CurrencyType,
  NetworkDto,
  WalletDto,
} from '../types/coreApi-types'
import DropdownCell from './DropdownCell'
import Toast from './Toast'
import CurrencyModal from '../screens/App/screens/Broker Service/components/CurrencyModal/CurrencyModal'
import IconUploadDropZone from './IconUploadDropZone'
import CDropdownMenu from './CDropdownMenu'

interface AddCurrencyModalProps {
  isOpen: boolean
  onClose: () => void
}

type FormData = {
  name: string
  isoCode: string
}

const AddCurrencyModal: React.FC<AddCurrencyModalProps> = ({
  isOpen,
  onClose,
}) => {
  const schema: yup.SchemaOf<FormData> = yup.object({
    name: yup.string().required('This field is required.'),
    isoCode: yup.string().required('This field is required.'),
  })
  const {
    register,
    handleSubmit,
    formState,
    reset: resetForm,
    getValues,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const toast = useToast()
  const currencyModal = useDisclosure()
  const [tab, setTab] = useState<CurrencyType>('CryptoCurrency')
  const [selectedNativeNetwork, setSelectedNativeNetwork] =
    useState<NetworkDto | null>(null)
  const [selectedBankAccount, setSelectedBankAccount] =
    useState<BankAccountDto | null>(null)
  const [selectedFiatCurrencyPeg, setSelectedFiatCurrencyPeg] =
    useState<CurrencyDto | null>(null)
  const [activeNetworks, setActiveNetworks] = useState<NetworkDto[]>([])
  const [activeWallets, setActiveWallets] = useState<WalletDto[]>([])
  const [files, setFiles] = useState<File[]>([])

  const { data: networks } = useGetNetworksQuery(null)
  const { data: wallets } = useGetWalletsQuery(null)
  const { data: bankAccounts } = useGetBankAccountsQuery(null)

  const [createCurrency, { isLoading: createCurrencyIsLoading }] =
    useCreateCurrencyMutation()
  const [uploadCurrencyIcon] = useUploadCurrencyIconMutation()

  const [
    getRawExchangeRate,
    {
      isFetching: rawExchangeRateIsFetching,
      data: rawExchangeRateData,
      isError: rawExchangeRateIsError,
    },
  ] = useLazyGetExchangeRateRawCmcInEurQuery()

  const onSubmit: SubmitHandler<FormData> = async ({ name, isoCode }) => {
    if (files.length > 0) {
      const res = await createCurrency({
        currencyType: tab,
        name,
        isoCode,

        fiatCurrencyPegId: selectedFiatCurrencyPeg?.id,
        nativeNetworkId: selectedNativeNetwork?.id,
        networkIds: activeNetworks.map(n => n.id),
        supportedSystemWalletIds: activeWallets.map(w => w.id),

        systemBankAccountId: selectedBankAccount?.id,
      }).unwrap()

      const formData = new FormData()
      formData.append('File', files[0], files[0].name)
      await uploadCurrencyIcon({ currencyId: res.id, formData })

      onClose()
    } else {
      toast({
        position: 'top-right',
        render: () => {
          return <Toast type="error">There were some missing fields.</Toast>
        },
      })
    }
  }

  useEffect(() => {
    if (!isOpen) {
      resetForm()
      setActiveNetworks([])
      setActiveWallets([])
      setSelectedFiatCurrencyPeg(null)
      setSelectedNativeNetwork(null)
      setSelectedBankAccount(null)
      setFiles([])
    }
  }, [isOpen])

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={false}
      title="Add new Currency"
      whiteBG
    >
      <SegmentTab
        tabs={[
          {
            title: 'Crypto',
            isActive: tab === 'CryptoCurrency',
            onClick: () => {
              setTab('CryptoCurrency')
            },
            tooltip: 'Choose this to create a new Crypto Currency.',
          },
          {
            title: 'Fiat',
            isActive: tab === 'FiatCurrency',
            onClick: () => {
              setTab('FiatCurrency')
            },
            tooltip: 'Choose this to create a new Fiat Currency.',
          },
        ]}
      />
      <div className="p-5 pt-0 space-y-5">
        <FormInput
          inputMode="text"
          registeredForm={register('name')}
          error={formState.errors?.name}
          label="Name"
        />
        <FormInput
          inputMode="text"
          registeredForm={register('isoCode')}
          error={formState.errors?.isoCode}
          label="ISO Code (Ticker)"
        />
        {tab === 'CryptoCurrency' && (
          <div className="flex justify-between items-center w-full">
            <button
              className="button max-w-[150px]"
              onClick={async () => {
                await getRawExchangeRate({
                  currencyFromIsoCode: getValues('isoCode'),
                })
              }}
            >
              Check rate of ticker
            </button>
            {rawExchangeRateIsFetching ? (
              <Spinner size="sm" />
            ) : rawExchangeRateIsError ? (
              <p className="text-description">No data found</p>
            ) : (
              <p className="text-description">
                1 {getValues('isoCode')} &#8776;{' '}
                {rawExchangeRateData?.exchangeRate}
                &euro;
              </p>
            )}
          </div>
        )}
        <IconUploadDropZone files={files} setFiles={setFiles} />
        {tab === 'CryptoCurrency' ? (
          <>
            <CDropdownMenu
              emptyText="Select a native network"
              label="Native network (optional)"
              tooltip="This should only be chosen if this Currency is Native to some Network. Each Network has only 1 Native Currency, and each Currency can only be Native to 1 Network."
              selectedIconSrc={selectedNativeNetwork}
              selectedText={selectedNativeNetwork?.name}
              renderList={networks?.map(network => {
                return (
                  <DropdownCell
                    key={network.id}
                    title={network.name}
                    subtitle={network.nativeCurrency?.isoCode}
                    onClick={() => setSelectedNativeNetwork(network)}
                    isActive={network === selectedNativeNetwork}
                    imageName={network}
                  />
                )
              })}
            />
            <button className="input text-left" onClick={currencyModal.onOpen}>
              {selectedFiatCurrencyPeg
                ? `Pegged to ${selectedFiatCurrencyPeg.name}`
                : 'Choose fiat currency peg'}
            </button>
            <div className="card">
              {activeNetworks.map(network => (
                <div
                  key={network.id}
                  className="flex justify-between items-center"
                >
                  <p className="text-description">{network.name}</p>
                  <p
                    className="text-description cursor-pointer p-2"
                    onClick={() => {
                      const existingActiveNetworkIndex =
                        activeNetworks.findIndex(x => x.id === network.id)

                      if (existingActiveNetworkIndex !== -1) {
                        setActiveNetworks(networks => {
                          const temp = [...networks]
                          temp.splice(existingActiveNetworkIndex, 1)
                          return temp
                        })
                      }
                    }}
                  >
                    x
                  </p>
                </div>
              ))}
            </div>
            <CDropdownMenu
              emptyText="Select active networks"
              label="Active networks"
              selectedIconSrc={null}
              selectedText={undefined}
              renderList={networks?.map(network => {
                return (
                  <DropdownCell
                    key={network.id}
                    title={network.name}
                    subtitle={network.nativeCurrency?.isoCode}
                    onClick={() => {
                      const existingActiveNetworkIndex =
                        activeNetworks.findIndex(x => x.id === network.id)
                      if (existingActiveNetworkIndex !== -1) {
                        setActiveNetworks(networks => {
                          const temp = [...networks]
                          temp.splice(existingActiveNetworkIndex, 1)
                          return temp
                        })
                      } else {
                        setActiveNetworks(networks => [...networks, network])
                      }
                    }}
                    isActive={false}
                    imageName={network}
                  />
                )
              })}
            />
            <div className="card">
              {activeWallets.map(wallet => (
                <div
                  key={wallet.id}
                  className="flex justify-between items-center"
                >
                  <p className="text-description">{wallet.address}</p>
                  <p
                    className="text-description cursor-pointer p-2"
                    onClick={() => {
                      const existingActiveWalletIndex = activeWallets.findIndex(
                        x => x.id === wallet.id
                      )
                      if (existingActiveWalletIndex !== -1) {
                        setActiveWallets(wallets => {
                          const temp = [...wallets]
                          temp.splice(existingActiveWalletIndex, 1)
                          return temp
                        })
                      }
                    }}
                  >
                    x
                  </p>
                </div>
              ))}
            </div>
            <CDropdownMenu
              emptyText="Select wallets"
              label="Wallets"
              selectedIconSrc={null}
              selectedText={undefined}
              renderList={[...(wallets ?? [])]
                ?.sort((a, b) => {
                  const aDisabled = !activeNetworks.find(
                    x => x.id === a.network.id
                  )
                  const bDisabled = !activeNetworks.find(
                    x => x.id === b.network.id
                  )
                  if (aDisabled && !bDisabled) return 1
                  else if (!aDisabled && bDisabled) return -1
                  else return 0
                })
                .map(wallet => {
                  return (
                    <DropdownCell
                      key={wallet.id}
                      disabled={
                        !activeNetworks.find(x => x.id === wallet.network.id)
                      }
                      title={wallet.address}
                      subtitle={wallet.network.name}
                      onClick={() => {
                        const existingActiveWalletIndex =
                          activeWallets.findIndex(x => x.id === wallet.id)
                        if (existingActiveWalletIndex !== -1) {
                          setActiveWallets(wallets => {
                            const temp = [...wallets]
                            temp.splice(existingActiveWalletIndex, 1)
                            return temp
                          })
                        } else {
                          setActiveWallets(wallets => [...wallets, wallet])
                        }
                      }}
                      isActive={false}
                    />
                  )
                })}
            />
          </>
        ) : (
          <CDropdownMenu
            emptyText="Select a bank account"
            label="Bank account"
            selectedIconSrc={null}
            selectedText={selectedBankAccount?.name}
            renderList={bankAccounts?.map(bankAccount => {
              return (
                <DropdownCell
                  key={bankAccount.id}
                  title={bankAccount.name}
                  subtitle={bankAccount.description || ''}
                  onClick={() => setSelectedBankAccount(bankAccount)}
                  isActive={bankAccount === selectedBankAccount}
                />
              )
            })}
          />
        )}
        <button
          className="button"
          disabled={
            !formState.isValid ||
            (tab === 'CryptoCurrency' &&
              (activeNetworks.length === 0 ||
                activeWallets.length === 0 ||
                activeNetworks.length !== activeWallets.length)) ||
            (tab === 'FiatCurrency' && !selectedBankAccount)
          }
          onClick={handleSubmit(onSubmit)}
        >
          {createCurrencyIsLoading ? <Spinner size="sm" /> : 'Submit'}
        </button>
      </div>
      <CurrencyModal
        isOpen={currencyModal.isOpen}
        onClose={currencyModal.onClose}
        onClickCurrency={c => {
          if (selectedFiatCurrencyPeg && c.id === selectedFiatCurrencyPeg.id) {
            setSelectedFiatCurrencyPeg(null)
          } else {
            setSelectedFiatCurrencyPeg(c)
          }
        }}
      />
    </DefaultModal>
  )
}

export default AddCurrencyModal
