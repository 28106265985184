import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'

interface AsyncAlertProps<T, Y> {
  title: ((data?: T) => string) | string
  bodyText: ((data?: T) => string) | string
  submitButtonText: string
  alertState: { isOpen: boolean; data?: T; onClickPayload?: Y }
  setAlertState: (alertState: { isOpen: boolean; data?: T }) => void
  confirmPromiseRef: React.MutableRefObject<((value: boolean) => void) | null>
  onClick: (payload?: Y) => Promise<any>
}

const AsyncAlert = <T extends {}, Y extends {}>({
  title,
  bodyText,
  submitButtonText,
  alertState,
  setAlertState,
  confirmPromiseRef,
  onClick,
}: AsyncAlertProps<T, Y>) => {
  const onClose = () => {
    setAlertState({ isOpen: false })
    confirmPromiseRef.current?.(false)
  }
  const cancelRef = useRef<HTMLButtonElement | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <AlertDialog
      isOpen={alertState.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent rounded="14px" overflow="hidden">
          <div className="p-7 bg-brand-light-background dark:bg-brand-dark-background">
            <div className="font-semi text-base mb-2 text-brand-light-text-default dark:text-brand-dark-text-default">
              {typeof title === 'function' ? title(alertState.data) : title}
            </div>

            <div className="text-description">
              {typeof bodyText === 'function'
                ? bodyText(alertState.data)
                : bodyText}
            </div>

            <div className="flex gap-1 mt-10">
              <button
                ref={cancelRef}
                onClick={onClose}
                className="button-secondary"
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                disabled={isLoading}
                onClick={async () => {
                  try {
                    setIsLoading(true)
                    await onClick(alertState.onClickPayload)
                    confirmPromiseRef.current?.(true)
                    onClose()
                  } catch (e) {
                    confirmPromiseRef.current?.(false)
                  } finally {
                    setIsLoading(false)
                  }
                }}
                className="button-danger"
              >
                {isLoading ? 'Loading' : submitButtonText}
              </button>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default AsyncAlert
