import { useToast } from '@chakra-ui/react'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CopyTextWrapper from '../../../../../components/CopyTextWrapper'
import CoptyTextWrapper from '../../../../../components/CopyTextWrapper'

interface BankInfoProps {
  title: string
  value?: string | null
  copy?: boolean
}
const BankInfoCell: React.FC<BankInfoProps> = ({ title, value, copy }) => {
  const toast = useToast()

  return (
    <div className="mr-5">
      <p className="text-description">{title}</p>
      <div className="flex gap-2 items-center">
        <p className="text-default">{value}</p>
        {copy && (
          <CopyTextWrapper text={value ? value : ''}>
            <span className="text-brand-accent dark:text-white cursor-pointer">
              <FontAwesomeIcon icon={faCopy} />
            </span>
          </CopyTextWrapper>
        )}
      </div>
    </div>
  )
}

export default BankInfoCell
