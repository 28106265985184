import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import DefaultModal from './DefaultModal'
import { useUpdateUserMutation } from '../redux/services/coreApi'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { Spinner } from '@chakra-ui/react'
import FormInput from './FormInput'

interface TemporaryServiceDisruptionModalProps {
  isOpen: boolean
  onClose: () => void
}

type FormData = {
  phone: string
}

const TemporaryServiceDisruptionModal: React.FC<
  TemporaryServiceDisruptionModalProps
> = ({ isOpen, onClose }) => {
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={false}
      title="Service Update: Temporary Suspension of Broker Services"
    >
      <div className="flex flex-col items-center">
        <div className="text-description m-6 mt-0 space-y-3">
          <p>Dear Valued Customer,</p>
          <p>This is a service update from Penning.</p>
          <p>
            First of all, thank you very much for your patience and we apologize
            for the inconvenience the trading halt has caused during this
            process.
          </p>
          <p>
            However, during the API updates and technical integration, our
            current tier-1 banking relations have ceased cooperating with us,
            based on the upcoming MiCA regulation later this year and market
            uncertainty.
          </p>
          <p>
            We’ve tirelessly spent the entire summer onboarding new banking
            rails, and expect to be fully operational again within one month.
          </p>
          <p>
            The full integration and updates might take less than a month
            however, this is the “worst case” scenario, and surely we will keep
            you updated at all times.
          </p>
          <p>
            In the meantime, we are looking for alternative means to facilitate
            larger orders, and will reach out to clients with large-sized
            orders.
          </p>
          <p>
            Again, we thank you for your patience and for choosing to work with
            Penning.
          </p>
          <button className="button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default TemporaryServiceDisruptionModal
