import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface SearchProps {
  onChange: (v: string) => void
  value: string
}

const Search: React.FC<SearchProps> = ({ onChange, value }) => {
  return (
    <div className="h-[48px] rounded-full bg-gray-50 flex items-center focus-within:bg-gray-100  transition-all">
      <div className="h-full ml-4 mr-2 flex items-center justify-center shrink-0 ">
        <FontAwesomeIcon
          icon={faSearch}
          className="w-3 h-3 text-brand-light-text-description"
        />
      </div>
      <input
        type="text"
        placeholder="Search for a currency"
        className="border-0 pl-0 placeholder:text-brand-light-text-subtle-description bg-transparent w-full h-full font-medium outline:none focus:outline-0 border-transparent focus:ring-0"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
      {value.length > 0 && (
        <div
          onClick={() => onChange('')}
          className="w-8 h-8 mr-2 transition-all rounded-full shrink-0 cursor-pointer flex items-center justify-center hover:bg-gray-200"
        >
          <FontAwesomeIcon icon={faClose} className="w-3 h-3" />
        </div>
      )}
    </div>
  )
}

export default Search
