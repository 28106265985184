import moment from 'moment'
import { ROUNDING_DIGITS_CRYPTO, ROUNDING_DIGITS_FIAT } from '../constants'
import { CurrencyType } from '../types/coreApi-types'

export function formatNumber(
  value: number | string,
  currency: {
    currencyType: CurrencyType
    isoCode: string
  },
  roundingDigits?: number
): string {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  const _roundingDigits =
    roundingDigits ||
    (currency?.currencyType == 'FiatCurrency'
      ? ROUNDING_DIGITS_FIAT
      : ROUNDING_DIGITS_CRYPTO)

  const numberFormatOptions: Intl.NumberFormatOptions = {
    maximumFractionDigits: _roundingDigits,
  }

  let optionalIsoCodeAtEnd = ''
  if (['EUR', 'DKK', 'USD'].includes(currency.isoCode)) {
    numberFormatOptions.style = 'currency'
    numberFormatOptions.currency = currency.isoCode
  } else {
    optionalIsoCodeAtEnd = ` ${currency.isoCode}`
  }

  return (
    Intl.NumberFormat('en-US', numberFormatOptions).format(value) +
    optionalIsoCodeAtEnd
  )
}

export enum DateFormatOption {
  FULL,
  PARTIAL,
  DATE,
}

export function formatUtcDate(
  date: string | Date,
  dateFormat: DateFormatOption = DateFormatOption.FULL
) {
  let formatString = ''
  switch (dateFormat) {
    case DateFormatOption.FULL:
      formatString = 'DD MMM YYYY HH:mm:ss'
      break
    case DateFormatOption.PARTIAL:
      formatString = 'DD MMM YYYY HH:mm'
      break
    case DateFormatOption.DATE:
      formatString = 'DD MMM YYYY'
      break
  }

  return moment.utc(date).local().format(formatString)
}
