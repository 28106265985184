import React, { useState } from 'react'
import DefaultSettingContainer from '../../../../components/DefaultSettingContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faShield,
  faArrowsRotate,
  faCircleXmark,
  faLeftRight,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'
import PendingInvite from './components/PendingInvite'
import Member from './components/Member'
import ChangeThemeCard from '../../../../components/ChangeThemeCard'
import SwitchSettingContainer from '../../../../components/SwitchSettingContainer'
import { useToast } from '@chakra-ui/react'

function SettingsScreen() {
  const changeDynamicSidebarPreference = () => {
    if (localStorage.getItem('dynamic-sidebar') === 'on') {
      localStorage.setItem('dynamic-sidebar', 'off')
    } else {
      localStorage.setItem('dynamic-sidebar', 'on')
    }
    window.dispatchEvent(new Event('storage'))
  }

  const [dynamicSidebar, setDynamicSidebar] = useState(
    localStorage.getItem('dynamic-sidebar') === 'on'
  )

  const [newsletter, setNewsletter] = useState(false)
  const toast = useToast()

  return (
    <div className="pb-20">
      <h1 className="h1">Settings</h1>
      {/* <h2 className="h2 mb-3">Security</h2>
      <DefaultSettingContainer
        title="Two-factor authentication"
        description="Lorem ipsum dolor sit amet consectetur. Pellentesque nunc orci dictum sagittis tortor odio."
        iconRender={<FontAwesomeIcon icon={faShield} size="lg" />}
        renderAction={<button className="button">Setup</button>}
      /> */}
      {/* <h2 className="h2 mb-3 mt-10">Investing settings</h2>
      <DefaultSettingContainer
        title="Reinvest"
        description="Lorem ipsum dolor sit amet consectetur. Pellentesque nunc orci dictum sagittis tortor odio."
        iconRender={<FontAwesomeIcon icon={faArrowsRotate} size="lg" />}
        renderAction={<button className="button">Automatic</button>}
      /> */}
      <h2 className="h2 mt-10 mb-3">Appearance</h2>
      <ChangeThemeCard />

      {/* <SwitchSettingContainer
        title="Miniaturized sidebar"
        description="Decide whether or not the sidebar should resize when hovering."
        enabled={dynamicSidebar}
        iconRender={<FontAwesomeIcon icon={faLeftRight} size="lg" />}
        onChange={() => {
          setDynamicSidebar(!dynamicSidebar)
          changeDynamicSidebarPreference()
        }}
      /> */}
      {/* <h2 className="h2 mt-10 mb-3">Notifications</h2>
      <SwitchSettingContainer
        title="Newsletter"
        description="Get weekly updates on whats happening in the DeFi and crypto industry written by the Penning team."
        enabled={newsletter}
        iconRender={<FontAwesomeIcon icon={faEnvelope} />}
        onChange={() => {
          toast({
            description: `${
              newsletter ? 'Unsubscribed from' : 'Subscribed to'
            } newsletter`,
            position: 'bottom-right',
            variant: 'solid',
            status: 'success',
            containerStyle: {
              fontFamily: 'GeneralSans-Semibold',
              fontSize: '14px',
              marginTop: '0px',
            },
          })

          setNewsletter(!newsletter)
        }}
      /> */}
    </div>
  )
}

export default SettingsScreen
