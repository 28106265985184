import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PageTab } from '../types/internal-types'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

interface Props {
  title: string
  tabs?: PageTab[]
  description?: string
  goBack?: () => void
  isLoading?: boolean
}

function TabMenu({ title, tabs, description, goBack, isLoading }: Props) {
  return (
    <div className="bg-brand-light-card dark:bg-brand-dark-card transition-all ">
      <div className="p-4">
        {goBack && (
          <div
            onClick={goBack}
            className="text-description hover:underline cursor-pointer mb-1 flex gap-1 items-center"
          >
            Go back
          </div>
        )}
        <h1 className="font-semi text-md text-brand-light-text-default dark:text-brand-dark-text-default transition-all">
          {title}
        </h1>
        {description && <p className="text-description">{description}</p>}
      </div>

      <div className="border-b-[1px] border-brand-light-card-border border-solid dark:border-brand-dark-card-border transition-all">
        {tabs && (
          <div className="md:mt-2 pl-5 flex items-center space-x-5 ">
            {tabs.map((tab, i) => {
              return (
                <div
                  key={i}
                  className={`font-semi text-xs flex items-center gap-1 border-b-[3px] border-solid pb-2 px-2 transition-all select-none ${
                    tab.active
                      ? 'border-blue text-brand-light-text-default dark:text-brand-dark-text-default'
                      : 'border-transparent text-brand-light-text-description dark:text-brand-dark-text-description'
                  } ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                  onClick={() => {
                    if (isLoading) return
                    tab.onPress()
                  }}
                >
                  {tab.title}
                  {tab.amount && (
                    <div className="h-4 min-w-[16px] px-1 rounded-full bg-slate-200 text-[10px] flex items-center justify-center text-slate-700">
                      {tab.amount === 'undefined' ? '0' : tab.amount}
                    </div>
                  )}
                  {tab.notification && (
                    <div className="h-1.5 w-1.5 rounded-full bg-blue-500" />
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default TabMenu
