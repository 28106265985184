import {
  faCircleCheck,
  faExclamationCircle,
  faGear,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

interface CompletedStepProps {
  title: string
  description?: string
  state: 'cancelled' | 'completed' | 'current'
}

const CompletedStep: React.FC<CompletedStepProps> = ({
  title,
  description,
  state,
}) => {
  const getState = () => {
    switch (state) {
      case 'current':
        return {
          text: 'text-brand-accent dark:text-white',
          icon: <FontAwesomeIcon icon={faGear} className="animate-spin" />,
        }
      case 'completed':
        return {
          text: 'text-brand-light-green dark:text-brand-dark-green',
          icon: <FontAwesomeIcon icon={faCircleCheck} />,
        }
      case 'cancelled':
        return {
          text: 'text-brand-light-red dark:text-brand-dark-red',
          icon: <FontAwesomeIcon icon={faExclamationCircle} />,
        }
    }
  }

  return (
    <div className="color-text-description flex ">
      <div className={`w-8 ${getState().text}`}>{getState().icon}</div>

      <div>
        <p className={`font-semi text-sm mt-0.5 mb-0.5 ${getState().text}`}>
          {title}
        </p>
        <p className="text-description">{description}</p>
      </div>
    </div>
  )
}

export default CompletedStep
