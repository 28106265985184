import React from 'react'
import PersonalDetailItem from '../../../../../../components/PersonalDetailItem'
import { BankAccountDto } from '../../../../../../types/coreApi-types'

interface BankAccountsProps {
  bankAccounts?: BankAccountDto[]
}

const BankAccounts: React.FC<BankAccountsProps> = ({ bankAccounts }) => {
  return (
    <div className="space-y-3">
      <h1 className="h1">Bank accounts</h1>
      {bankAccounts && bankAccounts.length === 0 && (
        <div className="flex justify-center text-description">
          No bank accounts
        </div>
      )}
      {bankAccounts &&
        bankAccounts.map((item, i) => {
          return (
            <div key={i} className="card gap-5 ">
              <PersonalDetailItem title="Bank Name" value={item.name} />
              {item.description && (
                <PersonalDetailItem
                  title="Description"
                  value={item.description}
                />
              )}

              {item.bankAccountDetails.accountNumber && (
                <>
                  <PersonalDetailItem
                    title="Registration"
                    value={`${item.bankAccountDetails.registration}`}
                  />
                  <PersonalDetailItem
                    title="Account number"
                    value={`${item.bankAccountDetails.accountNumber}`}
                  />
                </>
              )}
              {item.bankAccountDetails.iban && (
                <>
                  <PersonalDetailItem
                    title="IBAN"
                    value={`${item.bankAccountDetails.iban}`}
                  />
                  <PersonalDetailItem
                    title="BIC (SWIFT)"
                    value={`${item.bankAccountDetails.bic}`}
                  />
                  <PersonalDetailItem
                    title="Recipient name"
                    value={`${item.bankAccountDetails.recipientName}`}
                  />
                  <PersonalDetailItem
                    title="Recipient address"
                    value={`${item.bankAccountDetails.recipientAddress}`}
                  />
                  <PersonalDetailItem
                    title="Recipient postal/zip code"
                    value={`${item.bankAccountDetails.recipientZipCode}`}
                  />
                  <PersonalDetailItem
                    title="Recipient city"
                    value={`${item.bankAccountDetails.recipientCity}`}
                  />
                  <PersonalDetailItem
                    title="Recipient country"
                    value={`${item.bankAccountDetails.recipientCountry}`}
                  />
                </>
              )}
            </div>
          )
        })}
    </div>
  )
}

export default BankAccounts
