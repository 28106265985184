import { API_URL } from '../constants'

export default async function downloadSwapInvoice(
  swapId: string,
  sessionToken: string
) {
  const res = await fetch(`${API_URL}/swaps/${swapId}/invoice`, {
    headers: { Authorization: `Bearer ${sessionToken}` },
  })

  const blob = await res.blob()
  const newBlob = new Blob([blob])

  const blobUrl = window.URL.createObjectURL(newBlob)

  const link = document.createElement('a')
  link.href = blobUrl
  link.setAttribute('download', `${swapId}.pdf`)
  document.body.appendChild(link)
  link.click()
  link.parentNode!.removeChild(link)

  // clean up Url
  window.URL.revokeObjectURL(blobUrl)
}
