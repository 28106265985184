import React from 'react'
import { TransferStatus } from '../../../../../../types/coreApi-types'
import Tooltip from '../../../../../../components/Tooltip'

interface StatusBoxProps {
  onClick: () => void
  isActive: boolean
  transferStatus: TransferStatus
  disabled?: boolean
}

const StatusBox: React.FC<StatusBoxProps> = ({
  transferStatus,
  onClick,
  isActive,
  disabled,
}) => {
  const getClassName = () => {
    switch (transferStatus) {
      case 'Pending':
        return 'status-badge-pending '
      case 'Processing':
        return 'status-badge-processing'
      case 'Completed':
        return 'status-badge-success'
      case 'Cancelled':
        return 'status-badge-cancelled'
      default:
        return ''
    }
  }

  return (
    <div
      className="col-span-1"
      onClick={!isActive && !disabled ? onClick : undefined}
    >
      <Tooltip
        label={
          isActive
            ? 'This transaction is here now'
            : `Move to ${transferStatus}`
        }
      >
        <div
          className={`${getClassName()} w-full h-[60px] rounded-lg flex items-center justify-center cursor-pointer ${
            isActive ? 'border-solid border-[2px] border-black' : ''
          } ${disabled ? 'grayscale-[50%] cursor-not-allowed' : ''}`}
        >
          <div className="h-full  flex items-center justify-center">
            {transferStatus}
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export default StatusBox
