import { useToast } from '@chakra-ui/react'
import {
  faBuilding,
  faCircleCheck,
  faEnvelope,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Form from './components/Form'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import FormInput from '../../components/FormInput'
import FormCheckbox from '../../components/FormCheckbox'
import FormButton from '../../components/FormButton'
import passwordValidator from './utils/passwordValidator'
import AccountTypeItem from './components/AccountTypeItem'
import {
  useCreateAccountMutation,
  useGetCountriesQuery,
} from '../../redux/services/coreApi'
import { PenningProduct, Role } from '../../types/coreApi-types'
import AccountTypeForm from './components/AccountTypeForm'
import AnimateHeight from '../../components/AnimateHeight'
import MessageBox from '../../components/MessageBox'
import PenningWebsiteHeaderLink from '../../components/PenningWebsiteHeaderLink'
import FormSelect from '../../components/FormSelect'
import IndividualForm, { IndividualData } from './components/IndividualForm'

interface ForgotPasswordScreenProps {}

type FormData = {
  name: string
  address: string
  phone: string
  country: string
  email: string
  password: string
  confirmPassword: string
  vatNumber?: string
  referralCode?: string

  newsletter?: boolean
  termsOfService: boolean
}

const SignUpScreen: React.FC<ForgotPasswordScreenProps> = () => {
  const history = useHistory()
  const toast = useToast()
  const location = useLocation()
  const referralCode = new URLSearchParams(location.search).get('referralCode')

  const [page, setPage] = useState<
    'account-type' | 'individual-purpose' | 'account-info' | 'confirm'
  >('account-type')
  const [error, setError] = useState<string | null>(null)
  const [role, setRole] = useState<Role | null>(null)

  const countries = useGetCountriesQuery()
  const [createAccount, { isLoading: isCreatingAccount }] =
    useCreateAccountMutation()

  const [individiualData, setIndividualData] = useState<IndividualData | null>(
    null
  )

  const onSubmit: SubmitHandler<FormData> = async ({
    name,
    address,
    phone,
    email,
    password,
    vatNumber,
    referralCode,
    newsletter,
    country,
  }) => {
    const isOrganization = role === 'Organization'
    const isoCode = countries.data?.find(el => el.name === country)?.isoCode

    if (!role || !countries || !isoCode) return

    let intendedProductsToUse = (
      Object.keys(
        individiualData ? individiualData.intentOfUse : []
      ) as PenningProduct[]
    ).filter(k => individiualData?.intentOfUse[k])

    try {
      await createAccount({
        name,
        address,
        phone,
        email,
        password,
        role,
        vatNumber: isOrganization ? vatNumber : undefined,
        referralCode: referralCode === '' ? undefined : referralCode,
        newsletterAccepted: Boolean(newsletter),
        isoCode: isoCode,
        accountPurpose: individiualData?.accountPurpose,
        sourceOfFunds: individiualData?.sourceOfFunds,
        intendedProductsToUse,
      }).unwrap()
      setPage('confirm')
    } catch (err) {
      //@ts-ignore
      setError(err.data.errors)
    }
  }

  const schema: yup.SchemaOf<FormData> = yup.object({
    name: yup
      .string()
      .required('This field is required.')
      .matches(
        /.*\p{L}+ \p{L}+.*/u,
        'Please enter at least your first and last name'
      ),
    address: yup.string().required('This field is required.'),
    phone: yup.string().required('This field is required.'),
    country: yup.string().required('This field is required.'),
    email: yup
      .string()
      .email('Please enter a valid email.')
      .required('This field is required.'),
    password: passwordValidator().required('This field is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match.')
      .required('This field is required.'),
    vatNumber: yup.string().when([], {
      is: () => role === 'Organization',
      then: yup.string().required('This field is required'),
    }),
    referralCode: yup.string(),
    newsletter: yup.bool(),
    termsOfService: yup
      .boolean()
      .required('The terms of service must be accepted.')
      .oneOf([true], 'The terms of service must be accepted.'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (referralCode) {
      setValue('referralCode', referralCode)
    }
  }, [referralCode])

  const ConfirmContainer = () => {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 40 }}
          transition={{ ease: 'easeOut', duration: 0.4 }}
          className="flex flex-col w-full items-center max-w-[450px] p-10 rounded-2xl bg-brand-light-card dark:bg-brand-dark-card"
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            className="text-brand-accent dark:text-white text-2xl"
          />
          <h1 className="color-text-default font-semi mb-5 mt-3 text-xl">
            Account created successfully!
          </h1>
          <p className="text-description text-center">
            You can now login to your account with the following mail
            <span className="font-semi text-brand-accent dark:text-white">
              {' '}
              {getValues('email')}
            </span>
          </p>
          <button onClick={() => history.push('/')} className="button mt-10">
            Back to login
          </button>
        </motion.div>
      </AnimatePresence>
    )
  }

  return (
    <div className="bg-brand-background dark:bg-brand-dark-background overflow-scroll min-h-screen flex flex-col justify-center items-center p-3 ">
      <PenningWebsiteHeaderLink />
      {page === 'account-type' && (
        <AccountTypeForm
          onClickContinue={role => {
            setRole(role)
            if (role === 'Individual') {
              setPage('individual-purpose')
            } else {
              setPage('account-info')
            }
          }}
        />
      )}

      {page === 'individual-purpose' && (
        <IndividualForm
          onPressBack={() => {
            setError(null)
            setPage('account-type')
            setIndividualData(null)
          }}
          onPressContinue={data => {
            setError(null)
            setPage('account-info')

            setIndividualData(data)
          }}
          initialValues={individiualData ?? undefined}
        />
      )}

      {page === 'account-info' && (
        <div className="pt-40 pb-20">
          <Form
            direction="fromRight"
            title="Account info"
            onPressBack={() => {
              if (role === 'Individual') setPage('individual-purpose')
              else setPage('account-type')

              setError(null)
            }}
            backTitle="Back"
            isLoading={isCreatingAccount}
          >
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-7">
              <div className="space-y-3">
                {role === 'Organization' && (
                  <div className="space-y-3">
                    <FormInput
                      label="VAT number"
                      inputMode="text"
                      registeredForm={register('vatNumber')}
                      error={errors?.vatNumber}
                    />
                  </div>
                )}

                <FormInput
                  label={role === 'Organization' ? 'Company name' : 'Full name'}
                  inputMode="text"
                  registeredForm={register('name')}
                  error={errors?.name}
                />

                <FormInput
                  label="Address"
                  inputMode="text"
                  registeredForm={register('address')}
                  error={errors?.address}
                />
                <FormInput
                  label="Phone"
                  inputMode="text"
                  registeredForm={register('phone')}
                  error={errors?.phone}
                />
                <FormSelect
                  label="Country"
                  options={countries.data?.map(item => item.name) || []}
                  registeredForm={register('country')}
                  placeholder="Select a country"
                />
                <FormInput
                  label="Email"
                  inputMode="email"
                  registeredForm={register('email')}
                  error={errors?.email}
                />

                <FormInput
                  label="Password"
                  inputMode="text"
                  isSecure
                  registeredForm={register('password')}
                  error={errors?.password}
                  showPassword
                />
                <FormInput
                  label="Confirm password"
                  inputMode="text"
                  isSecure
                  registeredForm={register('confirmPassword')}
                  error={errors?.confirmPassword}
                  showPassword
                />
                <FormInput
                  label="Referral code (Optional)"
                  inputMode="text"
                  registeredForm={register('referralCode')}
                  error={errors?.referralCode}
                />
              </div>
              <div className="space-y-3">
                <FormCheckbox
                  id="newsletter-checkbox"
                  renderLabel={
                    <>
                      I want to receive emails about news and weekly updates on
                      the market.
                    </>
                  }
                  registeredForm={register('newsletter')}
                  error={errors?.newsletter}
                />

                <FormCheckbox
                  id="tos"
                  renderLabel={
                    <>
                      I agree to the{' '}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.penning.dk/legal/cookiepolitik"
                        className="hover:underline text-brand-blue dark:text-white"
                      >
                        terms of service.
                      </a>
                    </>
                  }
                  registeredForm={register('termsOfService')}
                  error={errors?.termsOfService}
                />
              </div>
              <AnimateHeight isVisible={error !== null}>
                <MessageBox title={error ?? ''} type="warning" />
              </AnimateHeight>
              <FormButton
                isLoading={false}
                title="Create account"
                loadingText="Creating your account"
              />
            </form>
          </Form>
        </div>
      )}

      {page === 'confirm' && <ConfirmContainer />}
    </div>
  )
}

export default SignUpScreen
