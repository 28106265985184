import * as yup from 'yup'
export const companyShareSchema = yup
  .string()
  .required('This field is required.')
  .test('isNumber', 'It must be a valid number', function (value) {
    return value ? !isNaN(parseInt(value)) : false
  })
  .test(
    'numberBetween0and100',
    'It must be a valid number between 0 and 100',
    function (value) {
      return value ? parseInt(value) <= 100 && parseInt(value) > 0 : false
    }
  )
