import { FaLinkedinIn } from 'react-icons/fa'
import {
  BsDribbble,
  BsGithub,
  BsGlobe,
  BsFacebook,
  BsInstagram,
  BsTwitter,
} from 'react-icons/bs'
import { IoLogoMedium } from 'react-icons/io5'

interface SocialIconProps {
  link: string
  type: string
}

export const SocialIcon: React.FC<SocialIconProps> = ({ link, type }) => {
  const renderIcon = () => {
    switch (type) {
      case 'dribbble':
        return <BsDribbble size="22px" className="inheiret" />
      case 'facebook':
        return <BsFacebook size="22px" className="inheiret" />
      case 'instagram':
        return <BsInstagram size="22px" className="inheiret" />
      case 'linkedin':
        return <FaLinkedinIn size="22px" className="inheiret" />
      case 'github':
        return <BsGithub size="22px" className="inheiret" />
      case 'web':
        return <BsGlobe size="22px" className="inheiret" />
      case 'medium':
        return <IoLogoMedium size="22px" className="inheiret" />
      case 'twitter':
        return <BsTwitter size="22px" className="inheiret" />
    }
  }

  return (
    <a
      className="z-10 flex items-center justify-center bg-brand-dark-background w-[60px] h-[60px] rounded-full lg:w-[50px] lg:h-[50px] hover:bg-white text-white hover:text-brand-dark-card origin-center group cursor-pointer group transition duration-300"
      aria-label={type}
      rel="noreferrer"
      target="_blank"
      href={link}
    >
      {renderIcon()}
    </a>
  )
}
