import React from 'react'
import {
  BankAccountDto,
  SwapDto,
  TransferDto,
} from '../../../../../../types/coreApi-types'
import Swaps from '../../Transfers/components/Swaps'

interface TransfersProps {
  swaps?: SwapDto[]
}

const Transfers: React.FC<TransfersProps> = ({ swaps }) => {
  return (
    <div className="space-y-3">
      <h1 className="h1">Transfers</h1>
      <Swaps swapsData={swaps} ignoreUserLink />
    </div>
  )
}

export default Transfers
