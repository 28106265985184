import React from 'react'
import PageWrapper from '../../../../../components/Pagewrapper'
import { useGetSwapsQuery } from '../../../../../redux/services/coreApi'
import Swaps from './components/Swaps'

interface TransfersScreenProps {}

const TransfersScreen: React.FC<TransfersScreenProps> = ({}) => {
  const { data: swapsData } = useGetSwapsQuery(null)

  return (
    <PageWrapper title="Transfers">
      <Swaps swapsData={swapsData} />
    </PageWrapper>
  )
}

export default TransfersScreen
