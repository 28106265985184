import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import AnimateHeight from './AnimateHeight'
import DefaultModal from './DefaultModal'
import DropdownCell from './DropdownCell'
import DropdownMenu from './DropdownMenu'
import MessageBox from './MessageBox'
import {
  useGetNetworksQuery,
  useCreateWalletMutation,
  useGetWalletsQuery,
} from '../redux/services/coreApi'
import { NetworkDto } from '../types/coreApi-types'
import Icon from './Icon'

interface AddWalletModalProps {
  isOpen: boolean
  onClose: () => void
}

const AddWalletModal: React.FC<AddWalletModalProps> = ({ isOpen, onClose }) => {
  const wallets = useGetWalletsQuery(null)
  const { data: networksData } = useGetNetworksQuery(null)
  const [createWallet, { isLoading }] = useCreateWalletMutation()

  const [selectedNetwork, setSelectedNetwork] = useState<NetworkDto | null>(
    null
  )
  const [address, setAddress] = useState<string>('')
  const [destinationTag, setDestinationTag] = useState<string>('')

  const hasDestinationTag = selectedNetwork?.name === 'XRP'

  const createWalletInputError =
    address.length === 0 ||
    selectedNetwork === null ||
    (hasDestinationTag && destinationTag.length === 0)

  const onSubmit = async () => {
    if (!selectedNetwork) return
    await createWallet({
      address,
      destinationTag,
      networkId: selectedNetwork.id,
    }).unwrap()
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      setAddress('')
      setDestinationTag('')
      setSelectedNetwork(null)
    }
  }, [isOpen])

  return (
    <DefaultModal
      isLoading={isLoading}
      isLoadingText="Adding your wallet..."
      isOpen={isOpen}
      onClose={() => {
        setSelectedNetwork(null)
        setAddress('')
        onClose()
      }}
      title="Add wallet"
    >
      <div className="p-5 pt-0 space-y-5 h-[500px] ">
        <div>
          <p className="mb-1 text-description">Wallet address</p>
          <input
            type="text"
            className="input-card"
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
        </div>

        <AnimateHeight isVisible={hasDestinationTag}>
          <div>
            <p className="mb-1 text-description">Destination tag</p>
            <input
              type="text"
              className="input-card"
              value={destinationTag}
              onChange={e => setDestinationTag(e.target.value)}
            />
          </div>
        </AnimateHeight>

        <div className="flex flex-col ">
          <p className="text-description mb-1">Network</p>
          <DropdownMenu
            maxH="310px"
            renderList={networksData?.map(network => {
              return (
                <DropdownCell
                  key={network.id}
                  title={network.name}
                  subtitle={network.nativeCurrency?.isoCode}
                  onClick={() => setSelectedNetwork(network)}
                  isActive={network === selectedNetwork}
                  imageName={network}
                />
              )
            })}
          >
            <div className="input-card text-start flex items-center pl-3 gap-2">
              <Icon src={selectedNetwork} size="base" />

              {selectedNetwork?.name}
              {!selectedNetwork && (
                <p className="text-description">Select a network</p>
              )}

              <div className="absolute right-0 top-0 bottom-0 flex items-center mr-3 text-brand-light-text-description">
                <FontAwesomeIcon icon={faCaretDown} size="sm" />
              </div>
            </div>
          </DropdownMenu>
        </div>

        <button
          disabled={createWalletInputError}
          onClick={onSubmit}
          className="button"
        >
          Add wallet
        </button>
      </div>
    </DefaultModal>
  )
}

export default AddWalletModal
