import React from 'react'

interface SupportOptionProps {
  icon: React.ReactNode
  title: string
  subtitle: string
  onClick: () => void
}

const SupportOption: React.FC<SupportOptionProps> = ({
  icon,
  title,
  subtitle,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="p-3 bg-gray-100 hover:bg-gray-200 dark:bg-brand-dark-background dark:hover:bg-brand-dark-background/50 transition-all py-6 rounded-xl select-none cursor-pointer flex flex-col items-center"
    >
      {icon}
      <p className="font-semi text-sm mt-3 dark:text-white">{title}</p>
      <p className="text-description">{subtitle}</p>
    </div>
  )
}

export default SupportOption
