import React from 'react'

export interface TabItem {
  title: string
  onClick: () => void
  isActive: boolean
}

interface MobileTabMenuProps {
  tabs: TabItem[]
}

const MobileTabMenu: React.FC<MobileTabMenuProps> = ({ tabs }) => {
  return (
    <div className="flex items-center bg-white dark:bg-brand-blue">
      {tabs.map((tab, idx) => {
        return (
          <div
            key={idx}
            onClick={tab.onClick}
            className={`flex-1 flex xl:hidden justify-center py-4 font-semi text-sm transition-all border-b-[4px] border-solid cursor-pointer ${
              tab.isActive
                ? 'border-brand-accent text-brand-light-text-default dark:text-white'
                : 'border-white dark:border-brand-dark-card-border text-brand-light-text-subtle-description dark:text-brand-dark-text-subtle-description'
            }`}
          >
            {tab.title}
          </div>
        )
      })}
    </div>
  )
}

export default MobileTabMenu
