import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface MessageBoxProps {
  type: 'warning'
  title: string
  description?: string
  children?: React.ReactNode
}

const MessageBox: React.FC<MessageBoxProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <div className="bg-brand-light-red-tint dark:bg-brand-dark-red p-3 rounded-lg flex">
      <div className="w-[30px] shrink-0">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="text-brand-light-red text-md dark:text-white"
        />
      </div>
      <div>
        <p className="text-xs font-semi text-brand-light-red dark:text-white mt-1">
          {title}
        </p>
        {description && (
          <p className="text-description dark:text-white">{description}</p>
        )}

        {children && <div className="mt-2 mb-1">{children}</div>}
      </div>
    </div>
  )
}

export default MessageBox
