import React from 'react'
import PageWrapper from '../../../../../components/Pagewrapper'
import {
  faBank,
  faChartSimple,
  faCheckCircle,
  faRightLeft,
  faUserSlash,
  faWallet,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormButton from '../../../../../components/FormButton'
import { useHistory } from 'react-router-dom'

interface CompletedProps {}

const Completed: React.FC<CompletedProps> = ({}) => {
  const history = useHistory()
  const actions = [
    {
      title: 'Add bank accounts',
      onClick: () => history.push('/app/bank-accounts'),
      icon: <FontAwesomeIcon icon={faBank} size="sm" />,
    },
    {
      title: 'Add a wallet',
      onClick: () => history.push('/app/wallets'),
      icon: <FontAwesomeIcon icon={faWallet} size="sm" />,
    },
    {
      title: 'Swap money',
      onClick: () => history.push('/app/broker-service'),
      icon: <FontAwesomeIcon icon={faRightLeft} size="xs" />,
    },
    {
      title: 'Invest',
      onClick: () => history.push('/app/broker-service'),
      icon: <FontAwesomeIcon icon={faChartSimple} size="sm" />,
      comingSoon: true,
    },
  ]

  return (
    <PageWrapper fixedWidth>
      <div className="card gap-2 mt-20 flex flex-col text-center items-center">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="h-6 w-6 mt-5 text-brand-light-green dark:text-brand-dark-green"
        />
        <h1 className="font-semi text-lg">Setup complete</h1>
        <span className="text-description w-2/3 ">
          Great job! You are now ready to use the Penning Client Experience.
        </span>

        <p className="mb-2 font-medium text-sm mt-8">What now?</p>
        <div className="w-full grid grid-cols-2 gap-2 max-w-[500px]">
          {actions.map((item, i) => {
            return (
              <button
                onClick={item.onClick}
                key={i}
                disabled={item.comingSoon}
                className="flex items-center px-4 py-3 gap-2 dark:hover:bg-brand-dark-background disabled:opacity-50 text-sm font-medium disabled:cursor-not-allowed hover:bg-brand-light-background border-[1px] transition-all cursor-pointer border-black/10 dark:border-white/20 border-solid rounded-md"
              >
                {item.icon}
                {item.title}
              </button>
            )
          })}
        </div>
      </div>
    </PageWrapper>
  )
}

export default Completed
